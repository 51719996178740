
import Axios from "axios"
import moment from "moment"
import { API } from "../../constants/api_url"
import { setPPFListData } from "../../RTK/Background/pendingData"
import APIServices from "../../service/APIService"
import { useSelector } from "react-redux"
import { DateTime } from "luxon"



//get location hierarchy data based on location [],roleassigned [], $roles[]
const getLocationData = (locationData, roleAssignments, roles) => {
    // Filter role assignments based on roles
    const relevantAssignments = roleAssignments.filter(assignment =>
        assignment.roles.some(role => roles.includes(role))
    );

    // Initialize the result as an empty array
    let result = [];

    // Loop through each relevant assignment
    relevantAssignments.forEach(assignment => {
        if (assignment.tier1_id === 0) {
            // If tier1_id is 0, return all locations
            result = locationData;
        } else {
            // Find the matching tier1 (country)
            let country = locationData.find(loc => loc.id === assignment.tier1_id);
            if (!country) return;

            if (assignment.tier2_id === 0) {
                // If tier2_id is 0, return the country and its regions
                result.push(country);
            } else {
                // Find the matching tier2 (region)
                let region = country.locationTwos.find(loc => loc.id === assignment.tier2_id);
                if (!region) return;

                if (assignment.tier3_id === 0) {
                    // If tier3_id is 0, return the region and its sites
                    result.push({
                        ...country,
                        locationTwos: [region]
                    });
                } else {
                    // Find the matching tier3 (site)
                    let site = region.locationThrees.find(loc => loc.id === assignment.tier3_id);
                    if (!site) return;

                    result.push({
                        ...country,
                        locationTwos: [{
                            ...region,
                            locationThrees: [site]
                        }]
                    });
                }
            }
        }
    });

    // Remove duplicate locations from the result
    result = result.filter((item, index, self) =>
        index === self.findIndex(t => t.id === item.id)
    );

    return result;
}
function checkRoleAccessByRoleIds(user_ids, roles, level, tier_id, roleAssignments, locationData, adminId) {
    console.log(user_ids, roles, level, tier_id, roleAssignments, locationData, adminId)
    // Step 1: Filter roleAssignments based on user_ids and roles
    const filteredAssignments = roleAssignments.filter(assignment =>
        user_ids.includes(assignment.user_id) &&
        assignment.roles.some(role => roles.includes(role))
    );

    // Step 2: Create a set of user IDs who have access
    const userIdsWithAccess = new Set();
    if (adminId) {
        userIdsWithAccess.add(adminId);
    }

    // Step 3: Check each filtered assignment for access
    filteredAssignments.forEach(assignment => {
        const { user_id, tier1_id, tier2_id, tier3_id } = assignment;

        // Get valid tier IDs for this specific assignment
        const validTierIds = getValidTierIdsForAssignment(tier1_id, tier2_id, tier3_id, locationData);

        if (level === 0) {
            // Global access
            userIdsWithAccess.add(user_id);
        } else if (level === 1 && validTierIds.countries.includes(tier_id) &&  !assignment.tier2_id ) {
            // Country level access
            userIdsWithAccess.add(user_id);
        } else if (level === 2 && (validTierIds.regions.includes(tier_id) || validTierIds.countries.includes(tier_id) ) &&  !assignment.tier3_id ) {
            // Region level access
            userIdsWithAccess.add(user_id);
        } else if (level === 3 && (validTierIds.businessUnits.includes(tier_id) || validTierIds.regions.includes(tier_id) || validTierIds.countries.includes(tier_id))) {
            // Business Unit level access
            userIdsWithAccess.add(user_id);
        }
    });

    // Step 4: Return the list of user IDs who have access
    return Array.from(userIdsWithAccess);
}

function getValidTierIdsForAssignment(tier1_id, tier2_id, tier3_id, locationData) {
    let countries = new Set();
    let regions = new Set();
    let businessUnits = new Set();

    locationData.forEach(country => {
        if (tier1_id === country.id || tier1_id === 0 || tier1_id === null) {
            countries.add(country.id);
            country.locationTwos.forEach(region => {
                if (tier2_id === 0 || tier2_id === region.id || tier2_id === null) {
                    regions.add(region.id);
                    region.locationThrees.forEach(bu => {
                        if (tier3_id === 0 || tier3_id === bu.id || tier3_id === null) {
                            businessUnits.add(bu.id);
                        }
                    });
                }
            });
        }
    });

    return {
        countries: Array.from(countries),
        regions: Array.from(regions),
        businessUnits: Array.from(businessUnits)
    };
}
function getFiscalYearsFromStartDate(start_date,fymonth) {
    
    const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).plus({ months: 1 });
    const currentDate = DateTime.now();

    let startFiscalYear, currentFiscalYear;
    const fiscalYears = [];

    if (fymonth === 1) {
        // When fiscal month is January, it's a single year
        startFiscalYear = startDate.year;
        currentFiscalYear = currentDate.year;

        for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
            fiscalYears.push({ name: year, label: `${year}` });
        }
    } else {
        // Normal fiscal year spanning two calendar years
        startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
        currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

        for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
            const label = `${year}-${(year + 1).toString().slice(-2)}`;
            fiscalYears.push({ name: year + 1, label });
        }

        // Include the current fiscal year only if the current month is before the fiscal year start month
        if (currentDate.month < fymonth) {
            fiscalYears.pop();
        }
    }

    return fiscalYears;
}
const getRPTextFormat = (item) => {
    if (item.length !== 0) {
        if (item.length >= 2) {
            
            const startDate = DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('LLL-yyyy');
            const endDate = DateTime.fromFormat(item[item.length - 1], 'MM-yyyy').toFormat('LLL-yyyy');
            return `${startDate} to ${endDate}`;
        } else {
            return DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('LLL-yyyy');
        }
    }
};
const filterSubmissionsByFiscalYear = (submissions, year,fymonth) => {
    const { startDate, endDate } = getFiscalYearRange(year, fymonth);

    return submissions.filter(submission => {
        const allDatesWithinRange = submission.reporting_period.every(period => {
            const periodDate = DateTime.fromFormat(period, 'MM-yyyy').startOf('month');
            return periodDate >= startDate && periodDate <= endDate;
        });

        return allDatesWithinRange;
    });
};

const getFiscalYearRange = (year, fymonth) => {
    let startDate, endDate;
    

    if (fymonth === 1) {
        startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
        endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
    } else {
        startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
        endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
    }

    return { startDate, endDate };
};
const getValidTierIds = (locationData, tier1_id, tier2_id, tier3_id) => {
    const countries = new Set();
    const regions = new Set();
    const businessUnits = new Set();

    locationData.forEach(country => {
        if (tier1_id === 0 || tier1_id === country.id) {
            countries.add(country.id);

            country.locationTwos.forEach(region => {
                if (tier2_id === 0 || tier2_id === region.id) {
                    regions.add(region.id);

                    region.locationThrees.forEach(businessUnit => {
                        if (tier3_id === 0 || (tier2_id === 0 && tier3_id === null) || tier3_id === businessUnit.id) {
                            businessUnits.add(businessUnit.id);
                        }
                    });
                }
            });
        }
    });

    return { countries: Array.from(countries), regions: Array.from(regions), businessUnits: Array.from(businessUnits) };
};
const filterAssignmentsByFiscalYear = (assignments, year,fymonth) => {
    const { startDate, endDate } = getFiscalYearRange(year, fymonth);
    const currentDate = DateTime.local().startOf('day');

    return assignments.filter(assignment => {
        
        const assignmentStartDate = assignment.start_date ? DateTime.fromISO(assignment.start_date, { zone: 'utc' }).startOf('day') : currentDate;
        const assignmentEndDate = assignment.end_date ? DateTime.fromISO(assignment.end_date, { zone: 'utc' }).startOf('day') : currentDate;

        return (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
            (assignmentEndDate >= startDate && assignmentEndDate <= endDate) ||
            (assignmentStartDate <= startDate && assignmentEndDate >= endDate);
    });
};
const filterDataByTierAndLocation = (data, locationData, tier1_id, tier2_id, tier3_id) => {
    if (tier1_id === 0 && tier2_id === null && tier3_id === null) {
        return data; // If tier is 0, null, null return the given data
    }

    const { countries, regions, businessUnits } = getValidTierIds(locationData, tier1_id, tier2_id, tier3_id);


    return data.filter(item => {
        if (tier1_id !== 0 && tier2_id === 0 && tier3_id === null) {
            // Case when we want all regions and sites under a country
            return (item.level === 1 && countries.includes(item.locationId)) ||
                (item.level === 2 && regions.includes(item.locationId)) ||
                (item.level === 3 && businessUnits.includes(item.locationId));
        } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id === 0) {
            // Case when we want a specific region and all its sites
            return (item.level === 2 && regions.includes(item.locationId)) ||
                (item.level === 3 && businessUnits.includes(item.locationId));
        } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id !== 0) {
            // Case when we want a specific site
            return item.level === 3 && businessUnits.includes(item.locationId);
        } else {
            // Case when we want only the specific country
            return item.level === 1 && countries.includes(item.locationId);
        }
    });
};
function groupArrayByKeys(dataArray, keys) {
    return dataArray.reduce((result, item) => {
        // Create a group key by concatenating the values of the specified keys
        const groupKey = keys.map(key => item[key]).join('-');

        // If the group key doesn't exist in the result, initialize it as an empty array
        if (!result[groupKey]) {
            result[groupKey] = [];
        }

        // Push the current item into the group
        result[groupKey].push(item);

        return result;
    }, {});
}
export { getLocationData,checkRoleAccessByRoleIds,getFiscalYearsFromStartDate,getRPTextFormat,filterSubmissionsByFiscalYear,filterDataByTierAndLocation,filterAssignmentsByFiscalYear,groupArrayByKeys }