import React, { useEffect, useState } from "react";
import useForceUpdate from "use-force-update";
import { API } from "../../../constants/api_url";
import APIServices from "../../../service/APIService";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { filterAssignmentsByFiscalYear, filterDataByTierAndLocation, filterSubmissionsByFiscalYear, getLocationData, getRPTextFormat, groupArrayByKeys } from "../../../components/BGHF/helper";
import './approver.css'
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

let standAlone = [], levelCount = 0
const KPIApproval = () => {

    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })

    const forceUpdate = useForceUpdate()
    const [dcflist, setDCFList] = useState([])
    const [indicatorlist, setIndicatorList] = useState([])
    const [overallindicatorlist, setOverallIndicatorList] = useState([])
    const [filter, setFilter] = useState({ year: null, country: 0, city: null, site: null })
    const status = [{ name: 'Approved and Locked', id: 1 }, { name: 'Pending Approval', id: 2 }, { name: 'Pending Submissions', id: 3 }, { name: 'Unlocked for Changes and Comments', id: 4 }, { name: 'Partial Assignment', id: 5 }, { name: 'Assignment Missing', id: 6 }]

    const [rawsitelist, setRawSiteList] = useState([])
    const [dcfass, setDCFAss] = useState([])
    const [overallqnsubmission, setOverallQNSubmission] = useState([])

    const entryStartDate = DateTime.fromISO(admin_data.information.startdate, { zone: 'utc' }).toJSDate()
    const [label1, label2, label3] = useSelector((state) => state.user.tierLabel);

    const [kpilist, setKPIList] = useState([])
    const [yearoption, setYearOption] = useState([])


    useEffect(() => {
        let uriString = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }

        let yrOptions = getFiscalYearsFromStartDate(admin_data.information.startdate)

        setYearOption(yrOptions)
        const promise0 = APIServices.get(API.AssignDCFClient_UP(admin_data.id))
        const promise1 = APIServices.get(API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise2 = APIServices.get(API.DCF_Title_Only)
        const promise3 = APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`)
        const promise4 = APIServices.get(API.DCF_Entity_UP(admin_data.id))
        const promise5 = APIServices.get(API.DCF_Entity_User_UP(admin_data.id))
        const promise6 = APIServices.get(API.Indicator_Approver_Ass_UP(admin_data.id))
        const promise7 = APIServices.get(API.QN_Submit_UP(admin_data.id))
        const promise8 = APIServices.get(API.GetRole_Up(admin_data.id))


        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7,promise8]).then((values) => {

            if (yrOptions.length && values[0].data.length !== 0) {
                let assApproverIndicator = values[6].data.filter(i => i.responsibility.includes(login_data.id))
                console.log(values[6].data)
                let shapedSite = []
                if(login_data.role === 'clientadmin'){
                    
                    shapedSite = values[3].data.map((item) => {
                        if (item.locationTwos) {
                            item.locationTwos = item.locationTwos.filter(
                                (locationTwo) =>
                                    locationTwo.locationThrees &&
                                    locationTwo.locationThrees.length > 0
                            );
                        }
                        return item;
                    }).filter((item) => item.locationTwos && item.locationTwos.length > 0);
                }else {
                    shapedSite = getLocationData(values[3].data, values[8].data.filter(i => i.user_id === login_data.id), [3]).map((item) => {
                        if (item.locationTwos) {
                            item.locationTwos = item.locationTwos.filter(
                                (locationTwo) =>
                                    locationTwo.locationThrees &&
                                    locationTwo.locationThrees.length > 0
                            );
                        }
                        return item;
                    }).filter((item) => item.locationTwos && item.locationTwos.length > 0);
                }
                 
console.log(shapedSite)
                setLocList({ country: [ {name:"Corporate" ,id:0} ,...shapedSite.map(location => ({ name: location.name, id: location.id }))] });
                setRawSiteList(shapedSite)
                setOverallQNSubmission(values[7].data)
                setDCFAss(values[5].data.filter(i => checkEntity(values[4].data, i)))

                setDCFList(values[2].data)
                let indicator_list = [], kpi_list = []
                const shapedCategory = values[1].data.map(item => {
                    if (item.newTopics) {
                        item.newTopics = item.newTopics.filter(topics =>
                            topics.newMetrics && topics.newMetrics.length > 0
                        );
                    }
                    return item;
                }).filter(item => item.newTopics && item.newTopics.length > 0)
                let overallmetric = shapedCategory.flatMap(i => i.newTopics && i.newTopics.flatMap(x => x.newMetrics))
                setOverallIndicatorList(overallmetric)
                shapedCategory.flatMap(i => i.newTopics).forEach((top) => {
                    if (values[0].data[0].topic_ids.includes(top.id) && (top.tag === null || parseFloat(top.tag) === admin_data.id)) {
                        top.newMetrics.forEach((met) => {

                            if ((Array.isArray(met.data1) && met.data1.length && met.data1[0].type === 0) && values[0].data[0].metric_ids.includes(met.id) && !indicator_list.map(i => i.id).includes(met.id) && (met.tag === null || parseFloat(met.tag) === admin_data.id)) {
                                indicator_list.push(met)


                            }
                        })
                    }
                })
                setIndicatorList(indicator_list.filter(i => assApproverIndicator.map(x => x.indicatorId).includes(i.id)).map(i => ({ ...i, refobj: assApproverIndicator.find(x => x.indicatorId === i.id) })))

                setFilter((prev) => ({ ...prev, year: yrOptions[yrOptions.length - 1].name }))


            }

        })
    }, [])
    const checkEntity = (entity, entityuser) => {
        let assignment = entity.find(i => i.id === entityuser.entityAssId)
        if (assignment) {
            if (entityuser.level === 0) {
                return assignment.tier0_ids.includes(0)
            } else if (entityuser.level === 1) {
                return assignment.tier1_ids.includes(entityuser.locationId)
            } else if (entityuser.level === 2) {
                return assignment.tier2_ids.includes(entityuser.locationId)
            } else if (entityuser.level === 3) {
                return assignment.tier3_ids.includes(entityuser.locationId)
            } else {
                return false
            }
        } else {
            return false
        }


    }


    useEffect(() => {
        console.log(filter)
        if (filter.year) {
            let month_array = generateMonthArray(filter.year, fymonth), kpi_list = []
            let filteredAssignment = filterAssignmentsByFiscalYear(dcfass, filter.year, fymonth)

            console.log(indicatorlist)
            let filteredQnAssByYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(overallqnsubmission, filter.year, fymonth), rawsitelist, filter.country, filter.city, filter.site)
            for (const indicatoritem of filterDerivedAndStandaloneWithIds(indicatorlist, overallindicatorlist)) {
                let obj = { ...indicatoritem }
                let asslevel = indicatoritem.refobj.levelOfApproval
                obj.active = asslevel === 1 ? (filter.country !== 0 && filter.city === 0 && filter.site === null) : asslevel === 2 ? (filter.country !== null && filter.city !== 0 && filter.site === 0) : asslevel === 3 ? (filter.country !== null && filter.city !== null && filter.site) : (filter.country === 0 && filter.city === null && filter.site === null)
                obj.dcf_ids = Array.from(new Set(indicatoritem.standalone_ids.flatMap(i => overallindicatorlist.find(x => x.id === i && x.newDataPoints) ? overallindicatorlist.find(x => x.id === i).newDataPoints.filter(y => Array.isArray(y.data1) && y.data1.length && y.data1[0].datasource).flatMap(z => z.data1[0].datasource) : null).filter(i => i)))

                let filteredUserAssignment = filteredAssignment.filter(i => obj.dcf_ids.includes(i.dcfId)).map(i => ({ ...i, periods: getPeriodsForAssignment(i.start_date, i.end_date, i.frequency, fymonth, filter.year) }))
                obj.approvingMonths = generateApprovedPeriods(filteredUserAssignment.map(i => i.periods))
                obj.labels = checkDataSources(obj.approvingMonths, overallqnsubmission, filteredAssignment.filter(i => obj.dcf_ids.includes(i.dcfId)), obj.dcf_ids, filter)
                obj.months = month_array
                console.log(obj, "assignment")
                kpi_list.push(obj)

            }
            setKPIList(kpi_list.filter(i => i.active).map(i => { delete i.newDataPoints; return i }))
        }
    }, [filter])

    const updateLocationFilterValue = (obj, val) => {

        let item = { ...filter, [obj]: val }
        let selected_item = { country: 0, city: 0, location: 0 }
        let country_list = [{ name: 'All Countries', id: 0 }]
        let city_list = [{ name: 'All Regions', id: 0 }]
        let location_list = [{ name: 'All Business Unit', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })
        if (obj === 'country') {
            item.city = val === 0 ? null : 0

            item.site = null
        }
        else if (obj === 'city') {

            item.site = val === 0 ? null : 0
        }

        setFilter(item)
        console.log(city_list, location_list, item, val)
        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))
    }

    const checkDataSources = (requireMonths, submissions, assignments, dcfIds, filterOption) => {

        let filteredSubmissionCurrent = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions.filter(i => dcfIds.includes(i.dcfId)), filterOption.year, fymonth), rawsitelist, filterOption.country, filterOption.city, filterOption.site)
        let filteredSubmissionPrevious = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions.filter(i => dcfIds.includes(i.dcfId)), (filterOption.year - 1), fymonth), rawsitelist, filterOption.country, filterOption.city, filterOption.site)
        let filteredAssignmentCurrent = filterDataByTierAndLocation(filterAssignmentsByFiscalYear(assignments, filterOption.year, fymonth), rawsitelist, filterOption.country, filterOption.city, filterOption.site)
        let filteredAssignmentPrevious = filterAssignmentsByFiscalYear(assignments, (filterOption.year - 1), fymonth)
        let result = [], submittedData = []
        for (const requiredrp of requireMonths) {
            let requiredAssignment = filterObjectsByDateRange(requiredrp, filteredAssignmentCurrent)
            console.log(requiredAssignment)
            let datasources = filterByReportingPeriods(requiredrp, submissions.filter(i => dcfIds.includes(i.dcfId) && filteredAssignmentCurrent.map(x => x.entityAssId).includes(i.entityAssId)))
            let groupedFilterAssignment = Object.keys(groupArrayByKeys(requiredAssignment, ['level', 'locationId']))
            const requiredAssignmentsWithMissingPeriods = requiredAssignment.flatMap(i => {
                // Get all periods for the assignment
                const periods = getPeriodsForAssignment(i.start_date, i.end_date, i.frequency, fymonth, filterOption.year);

                // Filter out already submitted periods and conditionally add refobj when found
                return periods
                    .map(period => {
                        // Find the corresponding submission for this specific period
                        const foundSubmission = submissions.find(sub =>
                            i.entityAssId === sub.entityAssId && sub.entityUserAssId === i.id &&
                            i.dcfId === sub.dcfId &&
                            getRPTextFormat(sub.reporting_period) === period &&
                            sub.type >= 1
                        );

                        // Return the assignment with the period, and refobj only if found
                        return {
                            ...i,
                            period,
                            ...(foundSubmission ? { refobj: foundSubmission } : {})
                        };
                    })
                    .filter(item => !item.refobj); // Return only the ones that are missing (no refobj)
            });
            console.log(requiredAssignment, dcfIds)
            // Result: all missing assignment-period combinations.
            result.push({ assignmentCount: groupedFilterAssignment.length, month: requiredrp, approverFrequency: getMonthCount(requiredrp), datasources, pending: requiredAssignmentsWithMissingPeriods, upcoming: !getDisplayStatus(requiredrp), checked: (!groupedFilterAssignment.length || !datasources.length || ((groupedFilterAssignment.length * dcfIds.length) !== requiredAssignment.length)) ? false : (datasources.filter(i => (i.type === 2 || i.type === 3)).length === (groupedFilterAssignment.length * dcfIds.length)) ? true : false, disabled: (((groupedFilterAssignment.length * dcfIds.length) !== requiredAssignment.length) || !groupedFilterAssignment.length || !datasources.length || !datasources.filter(i => (i.type === 2)).length) ? true : (datasources.filter(i => (i.type === 2 || i.type === 3)).length === (groupedFilterAssignment.length * dcfIds.length)) ? false : true, new: !groupedFilterAssignment.length ? false : (datasources.filter(i => i.type === 2).length !== 0 && datasources.filter(i => i.type === 2 || i.type === 3).length === (groupedFilterAssignment.length * dcfIds.length)), status: !groupedFilterAssignment.length ? 6 : (groupedFilterAssignment.length * dcfIds.length) !== requiredAssignment.length ? 5 : (datasources.filter(i => i.type === 3).length === (groupedFilterAssignment.length * dcfIds.length)) ? 1 : (datasources.filter(i => i.type === 2).length === (groupedFilterAssignment.length * dcfIds.length) || ((datasources.length === datasources.filter(i => i.type === 3 || i.type === 2).length) && datasources.filter(i => i.type === 3 || i.type === 2).length === (groupedFilterAssignment.length * dcfIds.length))) ? 2 : 3 })


        }
        return result

    }
    const getDisplayStatus = (rp) => {



        const [startMonth, endMonth] = rp.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();

        return endOfMonth.diff(currentDate, 'days').days <= 0;
    };
    function generateMonthArray(year, fymonth) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let result = [];

        // Fiscal year starting from fymonth
        if (fymonth === 1) {
            // Calendar year starting from January
            for (let i = 0; i < 12; i++) {
                result.push(`${months[i]}-${year}`);
            }
        } else {
            // Fiscal year starting from the given month (not January)
            // Generate 12 months, adjusting the year if necessary
            for (let i = 0; i < 12; i++) {
                let monthIndex = (fymonth - 1 + i) % 12;
                let currentYear = year - 1;

                // Determine if we need to roll over to the next year
                if (monthIndex < fymonth - 1) {
                    currentYear++;
                }

                result.push(`${months[monthIndex]}-${currentYear}`);
            }
        }

        return result;
    }
    const getPeriodsForAssignment = (startDate, endDate, frequency, fymonth, year) => {

        let start = DateTime.fromISO(startDate).toLocal();
        let fiscalYearStart = null
        if (fymonth === 1) {
            fiscalYearStart = DateTime.fromObject({ year, month: 1, day: 1 });
        } else {
            fiscalYearStart = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 });
        }
        // If start date is earlier than the fiscal year start, adjust it
        if (start < fiscalYearStart) {
            start = fiscalYearStart;
        }
        // Determine the current year
        const currentYear = DateTime.now().year;

        // If endDate is null, compute based on fiscal year and year condition
        if (!endDate) {


            // Set endDate based on fymonth and the adjusted endYear
            if (fymonth === 1) {
                endDate = DateTime.fromObject({ year, month: 12, day: 31 }); // Dec of the specified year
            } else {
                endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 31 }); // Mar(fymonth-1)-year
            }
        }

        let end = DateTime.fromISO(endDate); // Now `end` is defined either from input or calculated

        let periods = [];

        while (start <= end) {
            let periodEnd = start.plus({ months: frequency - 1 });
            if (periodEnd > end) {
                periodEnd = end;
            }

            // Create a period string
            const period =
                frequency === 1
                    ? start.toFormat('MMM-yyyy')
                    : `${start.toFormat('MMM-yyyy')} to ${periodEnd.toFormat('MMM-yyyy')}`;
            periods.push(period);

            // Move to the next period
            start = start.plus({ months: frequency });
        }

        return periods;
    };
    const sortPeriods = (periods) => {
        return periods.sort((a, b) => {
            const getStartDate = (period) => {
                const start = period.split(' to ')[0].trim();
                return DateTime.fromFormat(start, 'MMM-yyyy');
            };

            const dateA = getStartDate(a);
            const dateB = getStartDate(b);
            return dateA - dateB;
        });
    };

    // Function to expand a date range into an array of months
    const expandRange = (start, end) => {
        const startDate = DateTime.fromFormat(start, 'MMM-yyyy');
        const endDate = DateTime.fromFormat(end, 'MMM-yyyy');
        const months = [];
        let currentDate = startDate;

        while (currentDate <= endDate) {
            months.push(currentDate.toFormat('MMM-yyyy'));
            currentDate = currentDate.plus({ months: 1 });
        }

        return months;
    };

    // Function to remove duplicate single months that are part of a range
    const removeDuplicateMonths = (arrays) => {
        const expandedRanges = new Set();

        arrays.forEach(row => {
            row.forEach(period => {
                if (period.includes('to')) {
                    const [start, end] = period.split(' to ');
                    const rangeMonths = expandRange(start.trim(), end.trim());
                    rangeMonths.forEach(month => expandedRanges.add(month));
                }
            });
        });

        return arrays.map(row =>
            row.filter(period => {
                if (period.includes('to')) return true; // Keep ranges
                return !expandedRanges.has(period); // Remove single months if they exist in a range
            })
        );
    };

    // Function to generate approved periods based on available data, ensuring longest ranges are prioritized
    const generateApprovedPeriods = (arrays) => {
        const cleanedData = removeDuplicateMonths(arrays);
        const periodMap = new Map();

        // Populate the map with the longest period found
        cleanedData.forEach(row => {
            row.forEach(period => {
                const periodKey = period.replace(/\sto\s/g, ' to '); // Normalize spacing for comparison
                const [start, end] = periodKey.includes('to') ? periodKey.split(' to ') : [periodKey, periodKey];

                if (periodMap.has(start)) {
                    const currentEnd = periodMap.get(start);
                    if (DateTime.fromFormat(end, 'MMM-yyyy') > DateTime.fromFormat(currentEnd, 'MMM-yyyy')) {
                        periodMap.set(start, end); // Update with longer range
                    }
                } else {
                    periodMap.set(start, end); // Add new period
                }
            });
        });

        // Convert back to the "start to end" format
        const result = [];
        periodMap.forEach((end, start) => {
            if (start === end) {
                result.push(start);
            } else {
                result.push(`${start} to ${end}`);
            }
        });

        return sortPeriods(result);
    };



    function filterDerivedAndStandaloneWithIds(data, overall) {
        const childIds = new Set();
        const standaloneChildren = {};

        // Function to collect standalone IDs recursively for both children and nested parents
        function collectStandaloneIds(itemId) {
            const item = data.find(d => d.id === itemId);
            if (!item || !item.data1[0]) return [];

            let standaloneIds = item.data1[0].indicator.filter(id => {
                const child = overall.find(d => d.id === id);
                return child && child.data1[0]?.source === 1; // Check if the child is standalone
            });

            // Recursively collect standalone children from nested parents
            item.data1[0].indicator.forEach(id => {
                const child = overall.find(d => d.id === id);
                if (child && child.data1[0]?.source === 0) {
                    const nestedStandaloneIds = collectStandaloneIds(child.id);
                    standaloneIds = standaloneIds.concat(nestedStandaloneIds); // Merge nested results
                }
            });

            return standaloneIds;
        }

        // Collect standalone children for derived parents
        data.forEach(item => {
            if (item.data1[0]?.type === 0 && item.data1[0]?.source === 0) {
                const standaloneIds = collectStandaloneIds(item.id);
                if (standaloneIds.length > 0) {
                    standaloneChildren[item.id] = standaloneIds;
                }

                // Add all child IDs (standalone or not) to the set of child IDs
                item.data1[0].indicator.forEach(id => childIds.add(id));
            }
        });

        // Filter out derived children and attach standalone_ids to derived parents
        const filteredData = data.map(item => {
            // If item is a derived parent and has standalone children, add standalone_ids
            if (standaloneChildren[item.id]) {
                return { ...item, standalone_ids: standaloneChildren[item.id] };
            }

            // Check for standalone items
            if (item.data1[0]?.type === 0 && item.data1[0]?.source === 1) {
                return { ...item, standalone_ids: [item.id] };
            }

            // Retain only if it's not a derived child (id not in childIds)
            if (!childIds.has(item.id)) {
                return item;
            }

            return null; // Exclude derived children
        }).filter(item => item !== null); // Remove null values

        return filteredData;
    }

    function getFiscalYearsFromStartDate(start_date) {

        const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).toLocal();
        const currentDate = DateTime.now().toLocal();

        let startFiscalYear, currentFiscalYear;
        const fiscalYears = [];

        if (fymonth === 1) {
            // When fiscal month is January, it's a single year
            startFiscalYear = startDate.year;
            currentFiscalYear = currentDate.year;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                fiscalYears.push({ name: year, label: `${year}` });
            }
        } else {
            // Normal fiscal year spanning two calendar years
            startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
            currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                const label = `${year}-${(year + 1).toString().slice(-2)}`;
                fiscalYears.push({ name: year + 1, label });
            }

            // Include the current fiscal year only if the current month is before the fiscal year start month
            if (currentDate.month < fymonth) {
                fiscalYears.pop();
            }
        }

        return fiscalYears;
    }
    function parseMonthYear(monthYear) {
        return DateTime.fromFormat(monthYear, 'MMM-yyyy').startOf('month');
    }

    // Helper function to get the start and end of the month range
    function getMonthRange(period) {
        const rangeRegex = /^([A-Za-z]+-\d{4}) to ([A-Za-z]+-\d{4})$/;
        const singleMonthRegex = /^([A-Za-z]+-\d{4})$/;

        if (singleMonthRegex.test(period)) {
            const date = parseMonthYear(period);
            return [date, date.endOf('month')];
        } else if (rangeRegex.test(period)) {
            const [, startMonthYear, endMonthYear] = period.match(rangeRegex);
            const start = parseMonthYear(startMonthYear);
            const end = parseMonthYear(endMonthYear).endOf('month');
            return [start, end];
        } else {
            throw new Error("Invalid date format");
        }
    }

    // Main filtering function using Luxon
    function filterObjectsByDateRange(dateString, objects) {
        const [startRange, endRange] = getMonthRange(dateString);

        return objects.filter(obj => {
            const objStart = DateTime.fromISO(obj.start_date);
            const objEnd = obj.end_date ? DateTime.fromISO(obj.end_date) : DateTime.utc(); // Use current date if end_date is null

            return objStart <= endRange && objEnd >= startRange;
        });
    }
    function parseReportingPeriod(period) {
        return DateTime.fromFormat(period, 'MM-yyyy').startOf('month');
    }
    function filterByReportingPeriods(dateString, objects) {
        const [startRange, endRange] = getMonthRange(dateString);

        return objects.filter(obj => {
            return obj.reporting_period.every(period => {
                const periodDate = parseReportingPeriod(period);
                return periodDate >= startRange && periodDate <= endRange;
            });
        });
    }
    function getMonthCount(period) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const singleMonthRegex = /^([A-Za-z]+-\d{4})$/;
        const rangeRegex = /^([A-Za-z]+-\d{4}) to ([A-Za-z]+-\d{4})$/;

        // Helper function to get month index from "Month-Year"
        function getMonthIndex(monthYear) {
            const [month, year] = monthYear.split("-");
            return { monthIndex: monthNames.indexOf(month), year: parseInt(year) };
        }

        // Check if it's a single month or a range
        if (singleMonthRegex.test(period)) {
            return 1; // Single month always returns count 1
        } else if (rangeRegex.test(period)) {
            const [, startMonthYear, endMonthYear] = period.match(rangeRegex);
            const start = getMonthIndex(startMonthYear);
            const end = getMonthIndex(endMonthYear);

            let monthDiff = (end.year - start.year) * 12 + (end.monthIndex - start.monthIndex) + 1;
            return monthDiff; // Returns the number of months between the range
        } else {
            return 0
        }
    }
    const updateCheckboxValue = (checked, topindex, index) => {
        let loc = JSON.parse(JSON.stringify(kpilist))

        loc[topindex].labels[index].checked = checked
        setKPIList(loc)
        forceUpdate()
    }
    const renderTable = (scopeData, topindex) => {
        console.log(topindex, scopeData)
        return (
            <div className="data-table" key={scopeData.title} style={{ whiteSpace: 'nowrap' }}>
                <div className='col-12 grid m-0 header cur-pointer' onClick={() => { scopeData.labels.some(i => i.assignmentCount !== 0) && (scopeData.selected = scopeData.selected ? !scopeData.selected : true); forceUpdate() }}>
                    <div className='col-11  grid m-0 p-0 align-items-center' >
                        <div className=' p-0 m-0 text-three-dot' style={{ maxWidth: '90%' }}>{scopeData.title} {scopeData.labels.every(i => i.assignmentCount === 0) && <span className="mandatory">* Assignment Missing</span>} </div>


                        {/* <div className='ml-1'> <i onClick={(e) => { e.stopPropagation(); setMethodology({ text: scopeData.methodology, indicator: scopeData.title }); setMethodologyDialog(true) }} className='pi pi-exclamation-circle' /> </div> */}
                    </div>


                    <div className='col-1 grid m-0 justify-content-end'>

                        <div><i className={scopeData.selected ? 'pi pi-angle-up' : 'pi pi-angle-down'} /></div>
                    </div>

                </div>
                <div style={{ overflowX: 'auto' }}>
                    {scopeData.selected && <table style={{ minWidth: '100%' }}>
                        <tr>
                            <th colSpan={1}></th>
                            {scopeData.labels.map((label, index) => {

                                return (
                                    <th key={index} colSpan={label.approverFrequency} >
                                        <div className='col-12 grid m-0 p-0 justify-content-between align-items-center' >
                                            <div className='col-4 m-0 p-0 flex' >

                                                <Checkbox inputId={label.name} disabled={label.disabled} value={label.checked} onChange={(e) => { updateCheckboxValue(e.checked, topindex, index) }} checked={(label.checked || label.status === 1)} style={{ marginRight: "4px" }} />


                                            </div>

                                            <div className='col-6 m-0 p-0'>

                                            </div>
                                        </div>
                                    </th>)
                            })}
                            <th colSpan={3}>
                                Performance Deviation
                                {/* { scopeData.deviation  ? <> <i style={{color: !scopeData.deviationDirection ? 'red':'green'}} className={ 'pi ' +(!scopeData.deviationDirection ? 'pi-arrow-up' :'pi-arrow-down')} /> {scopeData.deviation} change YoY </> : 'NA'} */}
                            </th>
                            <th colSpan={1} rowSpan={2}>
                                Commentary
                            </th>
                        </tr>
                        <tr>
                            <th className='no-border-right fixed-columns' >Unit</th>

                            {scopeData.months.map((month, index) => <th colSpan={1} key={index}>{month}</th>)}
                            <th className='no-border-right  fixed-columns' >YTQ  </th>
                            <th className='no-border-right  fixed-columns' >YTQ  </th>
                            <th className='fixed-columns' >Change </th>

                        </tr>
                        <tr>
                            <th className='no-border-right fixed-columns' >{scopeData.data1[0].unit}</th>
                            {scopeData.approvingMonths.map((month, index) => <th colSpan={getMonthCount(month)} key={index}>{ }</th>)}
                            <th className='no-border-right  fixed-columns' >  </th>
                            <th className='no-border-right  fixed-columns' >  </th>
                            <th className='fixed-columns' > </th>

                        </tr>
                        <tr>
                            <th className='no-border-right fixed-columns' >Data Source(s)</th>
                            {scopeData.labels.map((label, index) => <th colSpan={label.approverFrequency} key={index}>{label.upcoming ? 'NA' : label.datasources.length ? 'View' : '-'}</th>)}
                            <th className='no-border-right  fixed-columns' >  </th>
                            <th className='no-border-right  fixed-columns' >  </th>
                            <th className='fixed-columns' > </th>

                        </tr>
                        <tr>
                            <td className='no-border-right fixed-columns' >Action</td>
                            {scopeData.labels.map((label, index) => <td colSpan={label.approverFrequency} key={index}>  <span className={(label.status === 1) ? 'cur-pointer clr-navy text-underline' : ''}  >{(label.status === 1) ? 'Revert Approval' : ''}</span> </td>)}

                            <td className='no-border-right  fixed-columns' >  </td>
                            <td className='no-border-right  fixed-columns' >  </td>
                            <td className='fixed-columns' > </td>

                        </tr>
                        <tr>
                            <td className='no-border-right fixed-columns' >Status</td>
                            {scopeData.labels.map((label, index) => {
                                const statusItem = status.find(i => i.id === label.status);

                                return (
                                    <td key={index} colSpan={label.approverFrequency} >
                                        {label.upcoming === false && statusItem && (
                                            <span className={`status ${statusItem.name.toLowerCase().replace(/\s+/g, '-')}`}>
                                                {statusItem.name}
                                            </span>
                                        )}
                                    </td>
                                );
                            })}
                            <td className='no-border-right  fixed-columns' >  </td>
                            <td className='no-border-right  fixed-columns' >  </td>
                            <td className='fixed-columns' > </td>

                        </tr>



                    </table>}
                </div>
            </div>
        );
    };
    const expandAll = () => {
        let loc = JSON.parse(JSON.stringify(kpilist))
        let find = kpilist.every(i => i.status !== 6 && i.selected )
        if (find) {
            loc.forEach((item, index) => {

                item.selected = false

            })
        } else {
            loc.forEach((item, index) => {
                if(item.status !== 6){
                    item.selected = true
                }
              

            })
        }
        setKPIList(loc)
        forceUpdate()
    }
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div>
                    <h1 style={{ marginBottom: '0px', fontWeight: '700', fontSize: '20px' }}>KPI Approval Portal</h1>
                    {/* <p style={{fontWeight: '400', fontSize: '16px'}}>Impedit quis repellat quia quas provident dolor repellendus.</p> */}
                </div>
                <div
                    className="block mt-2 md:mt-0 p-input-icon-left"

                >
                    <i className="pi pi-search" />
                    <InputText type="search" placeholder="Search..." style={{ width: '100%' }} />
                </div>
            </div>
            <div className='grid m-0 align-items-center justify-content-between' >

                <div className="grid m-0 col-10">
                    <div className="p-col-3 p-md-3">
                        <Dropdown value={filter.year} options={yearoption} optionValue="name" optionLabel="label" onChange={(e) => setFilter((prev) => ({ ...prev, year: e.value }))} placeholder="Select Year" />
                    </div>
                    <div className="p-col-3 p-md-3">
                        <Dropdown value={filter.country} options={locList.country} optionLabel="name" optionValue="id" onChange={(e) => { updateLocationFilterValue('country', e.value) }} placeholder="Select Country" />
                    </div>
                    {filter.country !== 0 &&
                        <div className="p-col-3 p-md-3">
                            <Dropdown value={filter.city} options={locList.city} optionLabel="name" optionValue="id" onChange={(e) => { updateLocationFilterValue('city', e.value) }} placeholder="Select Region" />
                        </div>}
                    {filter.country !== 0 && filter.city !== 0 &&
                        <div className="p-col-3 p-md-3">
                            <Dropdown value={filter.site} options={locList.location} optionLabel="name" optionValue="id" onChange={(e) => { updateLocationFilterValue('site', e.value) }} placeholder="Select Business Unit" />
                        </div>}
                </div>
                <div className='col-2 grid m-0 justify-content-center align-items-center' >
                    <Button style={{ height: 30 }} text label={kpilist.every(i => i.status !== 6 &&  i.selected) ? 'Collapse All' : 'Expand All'} onClick={expandAll} />
                </div>
            </div>
            {kpilist.map((item, index) => {
                return renderTable(item, index)
            })

            }
        </div>
    )


}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(KPIApproval, comparisonFn);
