
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import { API } from "../../constants/api_url";
import { DateTime } from "luxon";
const initialState = {
    // profile:{id:'12',name:'Venkat',mobno:'0000000'}
    userdetail: {},
    admindetail: {},
    config: [],
    tierLabel: [],
    fyStartMonth: { fymonth: 1 },
    tvsSubAdmin: false,
    tvsSubAdminRoles: [],
    allRoles: [],
}
export const fetchApi = createAsyncThunk('api/fetchApi1', async (token) => {
    return axios.get(API.GetMeNew, { headers: { "Authorization": "Bearer" + " " + token } }).then((res) => res.data)
})
export const fetchApi_TVS_Supplier = createAsyncThunk('api/fetchApi3', async (token) => {
    return axios.get(API.GetMeNew, { headers: { "Authorization": "Bearer" + " " + token } }).then((res) => res.data)
})
export const fetchApi_TVS = createAsyncThunk('api/fetchApi2', async ({ token, id_token }) => {
    return axios.get(API.TVS_GetMe, {
        headers: {
            "Authorization": "Bearer " + token,
            "x-id-token": id_token
        }
    }).then((res) => res.data)
})

const setData = (state, action) => {
    if (action.payload.role === 'clientadmin' || action.payload.role === 'clientuser' || action.payload.role === 'eisqradmin' || action.payload.role === 'consultantadmin') {
        if (action.payload.information.blocked === undefined || !action.payload.information.blocked) {
            let data = action.payload
            if (data.role === 'clientadmin') {
                console.log(DateTime.fromISO(data.fyStartMonth, { zone: 'utc' }).toLocal().month, DateTime.fromISO(data.fyStartMonth, { zone: 'utc' }).month + 1)
                data.information['cid'] = data.id
                data.clientId = data.id
                state.tierLabel = data.tierLabel ? data.tierLabel : ['Country', 'City', 'Site']
                state.fyStartMonth = data.fyStartMonth ? { fymonth: DateTime.fromISO(data.fyStartMonth, { zone: 'utc' }).month + 1 } : { fymonth: 1 }
                state.admindetail = data;
                state.userdetail = data;
                state.tvsSubAdmin = false
                state.tvsSubAdminRoles = []
            } else if (data.role === 'clientuser' && data.roles_data && data.admin) {
                let accept_roles = [2, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
                let roles = data.roles_data.flatMap(i => i.roles).some(i => accept_roles.includes(i))
                console.log(roles)
                if (roles) {
                    state.allRoles = Array.from(new Set(data.roles_data.flatMap(i => i.roles)))
                    let tvsSubAdminRole = [7, 8, 9, 10, 11, 12, 13, 14, 15]
                    state.tvsSubAdmin = data.roles_data.flatMap(i => i.roles).some(i => tvsSubAdminRole.includes(i))
                    if (data.roles_data.flatMap(i => i.roles).some(i => tvsSubAdminRole.includes(i))) {
                        state.tvsSubAdminRoles = Array.from(new Set(data.roles_data.flatMap(i => i.roles).filter(i => tvsSubAdminRole.includes(i))))
                    } else {
                        state.tvsSubAdminRoles = []
                    }
                    state.admindetail = { ...data.admin }
                    state.userdetail = { ...data }
                    state.tierLabel = data.admin.tierLabel ? data.admin.tierLabel : ['Country', 'City', 'Site']
                    state.fyStartMonth = data.admin.fyStartMonth ? { fymonth: DateTime.fromISO(data.admin.fyStartMonth, { zone: 'utc' }).month + 1 } : { fymonth: 1 }

                } else {
                    state.tvsSubAdmin = false
                    localStorage.removeItem("token");
                    Swal.fire({
                        icon: 'warning',
                        title: 'Alert',
                        text: 'Seems You Have not Assigned Admin Role for Access, Contact Your Organization Platform Support Team For Futher Details',
                        returnInputValueOnDeny: () => {
                            console.log('deny')
                        }
                    })
                }

            } else if (data.role !== 'clientuser') {
                state.admindetail = { ...data }
                state.userdetail = { ...data }
            } else {
                localStorage.removeItem("token");
                Swal.fire({
                    icon: 'warning',
                    title: 'Alert',
                    text: 'Seems you are not onboarded properly, contact admin for futher details',
                    returnInputValueOnDeny: () => {
                        console.log('deny')
                    }
                })
            }


        } else {
            localStorage.removeItem("token");
            Swal.fire({
                icon: 'error',
                title: 'Blocked',
                text: 'You have been blocked from platform, contact admin for futher details',
                returnInputValueOnDeny: () => {
                    console.log('deny')
                }
            })

        }
    } else {
        localStorage.removeItem("token");
        Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You have no access to this platform, contact admin for futher details',
            returnInputValueOnDeny: () => {
                console.log('deny')
            }
        })
    }

}

const userProfile = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedUserDetail: (state, action) => { state.userdetail = action.payload },
        resetLoggedUserDetail: (state) => {
            // localStorage.clear();
            localStorage.removeItem('token')
            state.tvsSubAdmin = false;
            state.tvsSubAdminRoles = [];
            state.allRoles = [];
            sessionStorage.clear(); state.userdetail = {}
        },

    },
    extraReducers: (builder) => {

        builder.addCase(fetchApi.fulfilled, (state, action) => { setData(state, action) })
            .addCase(fetchApi_TVS.fulfilled, (state, action) => { setData(state, action) })
            .addCase(fetchApi_TVS_Supplier.fulfilled, (state, action) => { setData(state, action) })


    }



})

export default userProfile.reducer
export const { setLoggedUserDetail, resetLoggedUserDetail } = userProfile.actions
