import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../../../../constants/api_url";

import { useHistory } from "react-router-dom";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { Tooltip } from "primereact/tooltip";
import Swal from 'sweetalert2'
import APIServices from "../../../../service/APIService";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { MultiSelect } from "primereact/multiselect";
import { getLocationData } from "../../../../components/BGHF/helper";
const { DateTime } = require('luxon')


const AdminHomeTVS = () => {

    const login_data = useSelector((state) => state.user.userdetail)
    const allRoles = useSelector((state) => state.user.allRoles)

    const admin_data = useSelector((state) => state.user.admindetail)


    const [certcount, setCertCount] = useState(0)
    const navigate = useHistory()

    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12" >

                <div>
                    <div className="col-12">

                        <div className="col-12 flex align-items-center" style={{ padding: '0px 20px' }}>
                            <span className="text-big-one"> Welcome {login_data.information.empname} !  </span>   <Tag className="ml-3 p-tag-blue" > Sub Admin</Tag>
                        </div>

                        <div className="col-12" style={{ padding: '0px 20px' }} >
                            <label className="text-big-one text-navy flex fs-16"> One place to access all features.
                            </label>
                            <label className="text-micro text-navy flex">Get started, access powerful features to manage your product and drive positive environmental, social, and governance impact with ease.</label>

                        </div>

                    </div>

                    <div className="col-12  grid ">
                        <div className='col-3  user-dashboard-card p-card' onClick={() => { navigate.push({ pathname: '/quantitative_assignments_users' }) }} style={{ borderBottom: '5px solid red' }} >
                            <div>   <label className="fs-30 fw-7"></label> </div>
                            <div> <label className="fs-18 fw-5 "> My Task </label>
                            </div><div>
                                <label className="fs-12 clr-gray"></label> </div>
                            <div>   <label className="fs-14 fw-7"> Go to My Task <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                        </div>
                        <div className='col-3  user-dashboard-card p-card' onClick={() => { navigate.push({ pathname: '/complete_quantitative_submission_status' }) }} style={{ borderBottom: '5px solid skyblue' }} >
                            <div>   <label className="fs-30 fw-7"></label> </div>
                            <div> <label className="fs-18 fw-5 "> Data Submisison Status </label>
                            </div><div>
                                <label className="fs-12 clr-gray"></label> </div>
                            <div>   <label className="fs-14 fw-7 "> Go to Data Submisison Status <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                        </div>
                        <div className='col-3  user-dashboard-card p-card' onClick={() => { navigate.push({ pathname: '/performance_board' }) }} style={{ borderBottom: '5px solid #FFC300' }} >
                            <div>   <label className="fs-30 fw-7"></label> </div>
                            <div> <label className="fs-18 fw-5 "> Section KPI Dashboard </label>
                            </div><div>
                                <label className="fs-12 clr-gray"> </label> </div>
                            <div>   <label className="fs-14 fw-7 "> Go to Section KPI Dashboard  <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                        </div>
                        <div className='col-3  user-dashboard-card p-card' style={{ borderBottom: '5px solid #FFC300' }} >
                            <div>   <label className="fs-30 fw-7"></label> </div>
                            <div> <label className="fs-18 fw-5 "> Policies & Procedures </label>
                            </div><div>
                                <label className="fs-12 clr-gray"> </label> </div>
                            <div>   <label className="fs-14 fw-7 "> Go to Policies & Procedures  <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                        </div>
                        {allRoles.includes(12) && <div className='col-3  user-dashboard-card p-card' onClick={() => { navigate.push({ pathname: '/add_suppliers' }) }}   style={{ borderBottom: '5px solid #FFC300' }} >
                            <div>   <label className="fs-30 fw-7"></label> </div>
                            <div> <label className="fs-18 fw-5 "> Supply Chain </label>
                            </div><div>
                                <label className="fs-12 clr-gray"> </label> </div>
                            <div>   <label className="fs-14 fw-7 "> Go to Supply Chain Dashboard  <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                        </div>}


                    </div>

                </div>
            </div>

        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AdminHomeTVS, comparisonFn);