const tvssection = {
    8: [396, 429, 442, 568, 236, 237, 238, 389, 263, 264, 410, 455, 456, 457, 458, 759, 411, 412, 413, 414, 415, 218, 418, 419, 382, 383, 460],
    9: [235, 421, 424, 487, 683, 756],
    10: [268, 417, 552],
    11: [224, 420, 422, 423, 426, 486, 535, 536, 551, 553, 684, 685, 703, 745, 753],
    12: [275, 276, 432, 433, 443, 444, 729, 361, 362, 363, 364, 367, 518, 637, 644, 645],
    13: [],
    14: [161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 185, 516, 612, 659, 660, 672, 673, 746, 748, 200, 201, 782, 481, 483, 801, 780, 781, 800, 203, 484, 485, 490, 611, 202, 497, 498, 499, 500, 501, 596, 499, 502, 503, 504, 505, 506, 507, 511],
    15: [199, 204, 205, 704, 705, 715, 755, 757, 247, 248, 251, 524, 709, 288, 289, 291, 292, 447, 449, 450, 452, 558, 559, 725, 736, 744, 341, 342, 343, 344, 345, 347, 348, 523, 525, 526, 528, 529, 702, 706, 711, 712, 713, 751, 282, 284, 435, 324, 326, 438, 550, 557, 567, 587, 589, 591, 592, 600, 606, 752, 294, 298, 440, 590, 309, 521, 522, 575, 577, 578, 720, 722, 723, 617, 654, 655, 806],
    16: [634, 716, 737, 738, 739, 740, 335, 743, 743, 453, 454, 527, 554, 569, 570, 620, 405, 425, 530, 687, 688, 368, 369, 370, 371, 372, 373, 374, 378, 380, 445, 690, 401, 402, 403, 691, 697, 714, 534, 588, 619]
}
export { tvssection }