import React, { useState, useEffect } from "react";
import "../reports/toc_style.css";
import { useHeadsObserver } from "./hooks";
import * as XLSX from "xlsx";
import useForceUpdate from "use-force-update";
import $ from "jquery";
import Axios from "axios";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { saveAs } from 'file-saver';
import moment from "moment";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Dropdown } from "primereact/dropdown";
import APIServices from "../../service/APIService";
import { hardcoded } from "../constants/hardcodedid";
import { Line } from "recharts";
import { layouts } from "chart.js";
// import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
pdfMake.fonts = {
    Lato: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/fonts/lato-light/lato-light.woff',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/fonts/lato-bold/lato-bold.woff',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/fonts/lato-normal-italic/lato-normal-italic.woff',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/fonts/lato-semibold/lato-semibold.woff'
              }
}


// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// pdfMake.fonts = {
//     Roboto: {
//         normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
//         bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
//         italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
//         bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
//       }
// }
const { DateTime } = require('luxon');

window.jQuery = $;
window.$ = $;
const dcf_id = [11, 10, 15, 257, 16, 36, 257, 292, 293]
const CSRDReport = () => {
    const [headings, setHeadings] = useState([]);
    const { activeId } = useHeadsObserver();
    const [tableData, setTableData] = useState([]);
    const [workbook, setWorkbook] = useState(null);
    const [firstSheet, setFirstSheet] = useState(null);
    const [year, setYear] = useState(2024);
    const [rfData, setRFData] = useState({});
    const selector = useSelector((state) => state.user.userdetail);
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)
    const locationList = useSelector(state => state.sitelist.locationList)
    const siteList = useSelector((state) => state.sitelist.siteList)
    const rflibrary = useSelector((state) => state.library.rf)
    const [apief, setApiEF] = useState([])
    const [subcat, setSubCat] = useState({ one: [], two: [], three: [], four: [] })
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [dcfass, setDCFAss] = useState([])
    const [dcflist, setDcfList] = useState([])
    const [response, setResponse] = useState([])
    const [report, setReport] = useState([])
    const [reportEF, setReportEF] = useState([])
    const [dpreport, setDpReport] = useState([])
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const forceUpdate = useForceUpdate();
    function formatSubscript__(inputString, findArray, replaceArray) {
        let result = [];
        let currentIndex = 0;

        for (let i = 0; i < findArray.length; i++) {
            const findText = findArray[i];
            const replaceValue = replaceArray[i];
            const index = inputString.toLowerCase().indexOf(findText, currentIndex);

            if (index === -1) {
                // If the findText is not found, add the remaining text and break
                result.push(inputString.substring(currentIndex));
                break;
            }

            // Add the text before the found substring
            result.push(inputString.substring(currentIndex, index));

            // Add the subscripted replaceValue as an object
            result.push(...getResult(findText, replaceValue));

            // Update the currentIndex to continue searching
            currentIndex = index + findText.length;
        }

        // Add any remaining text after the last replacement
        if (currentIndex < inputString.length) {
            result.push(inputString.substring(currentIndex));
        }

        // Filter out empty strings
        result = result.filter((item) => item !== '');

        return result;
    }

    function formatSubscript(inputString, findArray, replaceArray) {


        return inputString;
    }
    function getResult(str, str2) {
        let arr = str.split(str2.toString())
        arr.splice(1, 0, { text: str2, fontSize: 7, baseline: -5 })
        return arr
    }
    function ulOrOlToPdfMake(element) {
        const result = [];
        const listItems = Array.from(element.querySelectorAll('li'));

        listItems.forEach((li) => {
            const text = li.textContent.trim();
            if (text !== '') {
                result.push({ text });
            }
        });

        return result;
    }

    function convertImageToGrayscale(imageElement, callback) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
    
        // Set canvas dimensions to the image's dimensions
        canvas.width = imageElement.width;
        canvas.height = imageElement.height;
    
        // Draw the image onto the canvas
        context.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
    
        // Get image data and manipulate pixels to grayscale
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
    
        for (let i = 0; i < data.length; i += 4) {
            const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
            data[i] = data[i + 1] = data[i + 2] = avg; // Set R, G, B to the average
        }
    
        context.putImageData(imageData, 0, 0);
    
        // Pass the grayscale image as a data URL to the callback
        callback(canvas.toDataURL());
    }
    
    // pdf export
    async function exportTable2Excel(type) {
        let initialData = [

            {

                alignment: 'left',
                image: 'page1', width: 150,
                margin: [0, 200, 0, 0],





            },


            {
                alignment: 'left',
                margin: [   0,
                    20,
                    0,
                    30],
                text: [
                    { characterSpacing: -2, text: ' Corporate Sustainability  ' + '\n', fontSize: 40, color: '#FFFFFFF', bold: true, },

                    { characterSpacing: -2, text: 'Reporting Directive Report' + '\n', fontSize: 40, color: '#FFFFFFF', bold: true, lineHeight: 1.5 },


                    { characterSpacing: -2, text: year + ' - ' + (year + 1).toString().substr(2, 3) + '\n', fontSize: 25, color: '#FFFFFFF', bold: true, lineHeight: 2.0 },

                    { characterSpacing: 0, text: "Interim Report as on "+ DateTime.local().toFormat('MMMM dd, yyyy'), fontSize: 15, color: '#FFFFFFF' }
               
                ],
                pageBreak: 'after'
            },


            // {

            //     alignment: 'left',
            //     image: 'clientlogo', width: 150,
            //     margin: [0, 200, 0, 0],





            // },


            {
                toc: {
                    id: 'sectionHeader',
                    title: {
                        text: 'Index', style: 'tdHead', color: 'black', fontSize: 24,
                        margin: [0, 0, 0, 50] // Adds space below the title

                    },

                },
                pageBreak: 'after'
            }], data = []
        const div = document.getElementById('main')

        for (let i = 0; i < div.children.length; i++) {

            if (div.childNodes[i].tagName.toLowerCase() === 'sectionheader') {

                const headingText = div.childNodes[i].textContent.trim();

              


                data.push({
                    table: {

                        widths: ['*'],
                        body: [
                            [{ tocItem: 'sectionHeader', text: formatSubscript(div.childNodes[i].textContent, ['tco2e', 'n2o', 'ch4', 'co2'], ['2', '2', '4', '2']), style: 'secHead', border: [false, false, false, false] }], 
                        ],

                    }
                })
                data.push(
                    {
                        text: '', // Empty text

                        margin: [10, 10], // Adjust the margin for horizontal space

                    })
            }

            else if (div.childNodes[i].tagName.toLowerCase() === 'sectionheader1') {

                for (let child = 0; child < div.childNodes[i].children.length; child++) {

                    if (div.childNodes[i].childNodes[child].className.includes('container')) {
                        let content = generatePdfMakeContentFromTable(div.childNodes[i].childNodes[child].childNodes[0])

                    

                        data.push({
                            table: {
                                headerRows: 1,
                                widths: JSON.parse(JSON.stringify(content))[0].map((a, b) => { return b == 0 ? "*" : 'auto' }),
                          
                                style: 'tableStyle'
                            }
                        })
                        data.push(
                            {
                                text: '', // Empty text

                                margin: [0, 10], // Adjust the margin for horizontal space

                            })
                    }
                    else if (div.childNodes[i].childNodes[child].className.includes('text-under')) {
                        data.push({ text: formatSubscript(div.childNodes[i].childNodes[child].textContent, ['tco2e', 'n2o', 'ch4', 'co2'], ['2', '2', '4', '2']), style: 'text-under' })
                        data.push(
                            {
                                text: '', // Empty text

                                margin: [5, 5], // Adjust the margin for horizontal space

                            })
                    }

                    // METHODOLOGY
                    else {
                        if (div.childNodes[i].childNodes[child].tagName) {
                            if (div.childNodes[i].childNodes[child].tagName === 'SPAN') {
                                data.push({ text: formatSubscript(div.childNodes[i].childNodes[child].textContent, ['tco2e', 'n2o', 'ch4', 'co2'], ['2', '2', '4', '2']), style: 'boldBlue' })
                                data.push(
                                    {
                                        text: '', // Empty text

                                        margin: [5, 5], // Adjust the margin for horizontal space

                                    })
                            }

                            else {
                                data.push({ text: formatSubscript(div.childNodes[i].childNodes[child].textContent, ['tco2e', 'n2o', 'ch4', 'co2'], ['2', '2', '4', '2']) })
                                data.push(
                                    {
                                        text: '', // Empty text

                                        margin: [5, 5], // Adjust the margin for horizontal space

                                    })
                            }
                        }
                        else {
                            data.push({ text: div.childNodes[i].childNodes[child].textContent })
                            data.push(
                                {
                                    text: '', // Empty text

                                    margin: [5, 5], // Adjust the margin for horizontal space

                                })
                        }

                    }
                }
            }



            else {
                if (div.childNodes[i].children.length !== 0) {
                    for (let child = 0; child < div.childNodes[i].children.length; child++) {
                        let tag = div.childNodes[i].childNodes[child].tagName

                        if (tag) {

                            if (tag === 'OL') {
                                data.push({ ol: ulOrOlToPdfMake(div.childNodes[i].childNodes[child]) })
                                data.push(
                                    {
                                        text: '', // Empty text

                                        margin: [0, 5], // Adjust the margin for horizontal space

                                    })
                            }
                            else if (tag === 'UL') {
                                data.push({ ul: ulOrOlToPdfMake(div.childNodes[i].childNodes[child]) })
                                data.push(
                                    {
                                        text: '', // Empty text

                                        margin: [0, 5], // Adjust the margin for horizontal space

                                    })
                            }
                            else if (tag === 'TABLE') {
                                let content = generatePdfMakeContentFromTable(div.childNodes[i].childNodes[child])

                                const alternateColors = ['#D9F8C7',]; // Light gray and white for example

                                // Apply colors to rows
                                const styledContent = content.map((row, index) => {
                                    if (index === 0) {
                                        // Header row, no color
                                        return row;
                                    }
                                    return row.map((cell, cellIndex) => {
                                        return {
                                            text: cell.text,
                                            fillColor: alternateColors[index % 2] // Alternate colors
                                        };
                                    });
                                });

                                data.push({
                                    table: {
                                        headerRows: 1,
                                        widths: JSON.parse(JSON.stringify(content))[0].map((a, b) => {
                                            return b == 0 ? "*" : 'auto'
                                        }),
                                        body: styledContent,
                                        style: 'tableStyle',

                                    },
                                    layout: 'noBorders' // Remove all borders
                                })
                                data.push(
                                    {
                                        text: '', // Empty text

                                        margin: [0, 10], // Adjust the margin for horizontal space

                                    })


                            }

                            else if (tag === 'IMG') {
                                data.push({ image: div.childNodes[i].childNodes[child].src })

                                data.push(
                                    {
                                        text: '', // Empty text

                                        margin: [0, 5], // Adjust the margin for horizontal space

                                    })
                            }

                            // Operational Boundaries and Inventory Inclusions

                            else if (tag === 'BR') {
                                let txt = `Definition: Operational Boundaries requires choosing the scope of emissions that will be reported. There are three scopes of emissions that can be reported:
                       \n Scope 1: Direct GHG Emissions from company owned or controlled sources
                       \n Scope 2: Indirect GHG Emissions from purchased electricity or steam.
                       \n According the GHG Protocol Corporate Reporting Standard, Scope 1 and Scope 2 emissions must be reported. Scope 3 emissions are voluntary`
                                data.push({ text: txt })
                                data.push(
                                    {
                                        text: '', // Empty text

                                        margin: [0, 5], // Adjust the margin for horizontal space

                                    })
                            }
                            // .....................

                            // Image Source: USEPA
                            else {

                                data.push({ text: formatSubscript(div.childNodes[i].childNodes[child].textContent, ['tco2e', 'n2o', 'ch4', 'co2'], ['2', '2', '4', '2']) })
                                data.push(
                                    {
                                        text: '', // Empty text

                                        margin: [0, 5], // Adjust the margin for horizontal space

                                    })
                            }
                            // .....................
                        }


                    }
                }
                else {

                    data.push({
                        text: formatSubscript(div.childNodes[i].textContent,
                            ['tco2e', 'n2o', 'ch4', 'co2'],
                            ['2', '2', '4', '2']),
                        style: 'customText'
                    })
                    data.push(
                        {
                            text: '', // Empty text

                            margin: [5, 5], // Adjust the margin for horizontal space

                        })
                }




            }
        }

        data.forEach((k) => {
            if (k.table) {
                if (!haveSameSubarrayLengths(k.table.body)) {
                    console.log(k.table.body)
                }
            }
        })

        let images = {
            clientlogo: document.getElementById('clientlogo').src,
            page1: document.getElementById('page1').src,
            page2: document.getElementById('page2').src
        }

        const header = (currentPage, pageCount, pageSize) => {
            if (currentPage !== 1) {
                return {
                    columns: [
                        {
                            text: `Corporate Sustainability Reporting Directive Report` + year.toString().substr(2, 3),
                            style: 'headerText',
                            margin: [30, 20],
                            fit: [40, 40],
                            alignment: 'left'
                        },
                        {
                            image: 'clientlogo',
                            fit: [40, 40],
                            margin: [0, 5, 15, 0],
                            alignment: 'right'
                        },
                    ],
                    // Add margins to the header
                }

            }
        }
        const documentDefinition = {
            info: {
                title: 'Corporate Sustainability Reporting Directive Report - ' + DateTime.local().toFormat('MMMM dd, yyyy'),
                author: 'Navigos',
                subject: 'CFP Report',
                keywords: 'Dont share unless people within same organization',
                producer: 'EiSqr',


            },
            // userPassword: 'Report@',
            // ownerPassword: '123456',
            permissions: {
                printing: 'highResolution', //'lowResolution'
                modifying: false,
                copying: false,
                annotating: true,
                fillingForms: true,
                contentAccessibility: true,
                documentAssembly: true
            },
            pageSize: 'A4',
            pageMargins: [30, 50, 30, 30], // [left, top, right, bottom] margins
            header,
            footer: function (currentPage, pageCount) {
                if (currentPage === 1) {
                    return {
                        text: 'Page ' + currentPage + ' of ' + pageCount,
                        alignment: 'center', fontSize: 12, color: '#FFFFFFF'
                    };
                } else {
                    return {
                        text: 'Page ' + currentPage + ' of ' + pageCount,
                        alignment: 'center', fontSize: 12
                    }

                }
            },
            content: [
                ...initialData, ...data

            ],
            // defaultStyle: {
            //     font: 'Roboto'
            //   },

            background: function (currentPage) {

                if (currentPage === 1) {
                    return [
                        {
                            canvas: [
                                { type: 'rect', x: 0, y: 0, w: 595.28, h:841.89 * 0.9, color: '#00349F' }, // 80% blue
                                { type: 'rect', x: 0, y: 841.89 * 0.9, w: 595.28, h: 595.28 * 0.1, color: '#FFFFFF' }, // 20% white
                              ]
                        },
                        {
                            image: 'page2',  // The ID for your new image
                            width: 150, // Adjust as needed
                            absolutePosition: { x: 400, y: 10 } // Adjust the x and y to position it in the top right
                        },
                        {
                            image: 'clientlogo',  // The ID for your new image
                            width: 80, // Adjust as needed
                            absolutePosition: { x: 50, y: 760 } 
                        }
                    ]
                }
            },



            images,
            styles: {


                tdHead: {
                    bold: true, alignment: 'center', valign: 'middle', fillColor: '#315874', color: 'white', fontFamily: 'Roboto'
                },

               

                customText: {
                    fontSize: 12, // Desired font size

                    bold: false,// Set to true if you want the text to be bold


                },

                secHead: {
                    bold: true, alignment: 'left', padding: [10, 10], color: '#315975', fontSize: 20,
                    
                },
                'text-under': {
                    bold: true, color: '#A3E97A '
                },
                headerText: {
                    fontSize: 14,
                    bold: true,
                    color: 'para', // Text color
                }, boldBlue: {
                    color: '#315874',
                    "marginLeft":25,
                    bold: true
                }
            },
            defaultStyle: {
                font: 'Lato'
              }
        };

        console.log(documentDefinition)

        if (type === 0) {
            const pdf = pdfMake.createPdf(documentDefinition);

            pdf.download('CSRD.pdf');

        } else {
            pdfMake.createPdf(documentDefinition).open({}, window.open('', '_blank'));

        }



    }

    function haveSameSubarrayLengths(data) {
        if (data.length < 2) {
            // If there are fewer than 2 subarrays, they are considered to have the same length.
            return true;
        }

        const firstSubarrayLength = data[0].length;

        for (let i = 1; i < data.length; i++) {
            if (data[i].length !== firstSubarrayLength) {
                return false;
            }
        }

        return true;
    }

    function generatePdfMakeContentFromTable(table) {

        if (!table) {
            console.error(`Table  not found.`);
            return [];
        }

        let contentArray = [], maxCol = 0
        for (let i = 0; i < table.rows.length; i++) {
            if (maxCol <= table.rows[i].cells.length) {
                maxCol = table.rows[i].cells.length
            }
        }

        for (let i = 0; i < table.rows.length; i++) {
            const rowArray = [];

            for (let j = 0; j < table.rows[i].cells.length; j++) {
                const cell = table.rows[i].cells[j];
                const colSpan = cell.getAttribute('colspan');
                const rowSpan = cell.getAttribute('rowspan');
                const cellText = cell.textContent.trim();

                const cellObject = { text: cellText };
                cellObject.colSpan = parseInt(colSpan)
                cellObject.rowSpan = parseInt(rowSpan)
                cellObject.style = cell.getAttribute('class');
                rowArray.push(cellObject);
                if (parseInt(colSpan) > 1) {

                    for (let j = 0; j < parseInt(colSpan) - 1; j++) {
                        rowArray.push({});
                    }

                }

            }

            contentArray.push(rowArray);


        }
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {

                if (contentArray[index - 1]) {
                    contentArray[index - 1].forEach((k, ind) => {
                        if (k.rowSpan) {
                            if (k.rowSpan > 1) {
                                if (k.colSpan === 1) {
                                    i.splice(ind, 0, { text: '', colSpan: k.colSpan, rowSpan: k.rowSpan - 1 })
                                } else {
                                    let newind = ind
                                    for (let j = 0; j < parseInt(k.colSpan); j++) {
                                        i.splice(newind, 0, {})
                                        newind++
                                    }
                                }
                            }
                        } else {
                            for (let j = 0; j < (maxCol - Object.keys(i).length); j++) {

                                // i.push({id:1});
                            }
                        }
                    })
                }
            }
        })
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                let len = Object.keys(i).length
                for (let j = 0; j < (maxCol - len); j++) {

                    i.push({});
                }

            }
        })
        return contentArray;
    }

    function isDateInFiscalYear(year, dateString) {
        const { start, end } = parseDateString(dateString);
        let curYear = year
        if (fymonth !== 1) {
            curYear = year - 1

        }
        const startDate = DateTime.fromFormat(start.trim(), 'MMM-yyyy');
        const endDate = DateTime.fromFormat(end.trim(), 'MMM-yyyy');
        const fiscalYearStart = DateTime.fromObject({ year: curYear, month: fymonth, day: 1 }); // April 1 of the previous year
        const fiscalYearEnd = DateTime.fromObject({ year: year, month: fymonth - 1 || 12, day: DateTime.fromObject({ year: year, month: fymonth - 1 || 12 }).daysInMonth }); // March 31 of the given year
        console.log((startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
            (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
            (startDate <= fiscalYearStart && endDate >= fiscalYearEnd), dateString, curYear, year)
        return (
            (startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
            (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
            (startDate <= fiscalYearStart && endDate >= fiscalYearEnd)
        );
    }

    function parseDateString(dateString) {
        if (dateString.includes('to')) {
            const [start, end] = dateString.split('to');
            return { start, end };
        } else {
            return { start: dateString, end: dateString };
        }
    }

    const getRPTextFormat = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
                    "LLL-yyyy"
                );
                const endDate = DateTime.fromFormat(
                    item[item.length - 1],
                    "MM-yyyy"
                ).toFormat("LLL-yyyy");
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
            }
        }
    };

    const WaterWithdrawl_246 = (yr) => {
        let surface = 0, ground = 0, third = 0, sea = 0, other = 0
        let surface1 = 0, ground1 = 0, third1 = 0, sea1 = 0, other1 = 0
        let submissions_cy = JSON.parse(JSON.stringify(response)).filter(i => i.dcfId === 246 && isDateInFiscalYear(yr, getRPTextFormat(i.reporting_period)))
        let submissions_py = JSON.parse(JSON.stringify(response)).filter(i => i.dcfId === 246 && isDateInFiscalYear(yr - 1, getRPTextFormat(i.reporting_period)))

        if (submissions_cy.length) {

            for (const item of submissions_cy) {
                console.log(item)
                item.response.forEach((dp) => {
                    let val = dp.DPAN0049A === 1 ? (parseFloat(dp.DPAN0049) / 1000) : dp.DPAN0049A === 2 ? parseFloat(dp.DPAN0049) : (parseFloat(dp.DPAN0049) * 1000)

                    if (dp.DPAN0048 === 1) {
                        surface += val
                    } else if (dp.DPAN0048 === 2) {
                        ground += val
                    } else if (dp.DPAN0048 === 3) {
                        sea += val
                    } else if (dp.DPAN0048 === 5) {
                        third += val
                    } else {
                        other += val
                    }
                })
            }
        }
        if (submissions_py.length) {

            for (const item of submissions_py) {
                console.log(item)
                item.response.forEach((dp) => {
                    let val = dp.DPAN0049A === 1 ? (parseFloat(dp.DPAN0049) / 1000) : dp.DPAN0049A === 2 ? parseFloat(dp.DPAN0049) : (parseFloat(dp.DPAN0049) * 1000)


                    if (dp.DPAN0048 === 1) {
                        surface1 += val
                    } else if (dp.DPAN0048 === 2) {
                        ground1 += val
                    } else if (dp.DPAN0048 === 3) {
                        sea1 += val
                    }
                    else if (dp.DPAN0048 === 5) {
                        third1 += val
                    } else {
                        other1 += val
                    }
                })
            }
        }
        console.log(surface, ground, third, sea, other)
        let total = surface + ground + third + sea + other
        let total1 = surface1 + ground1 + third1 + sea1 + other1
        return (
            <>
                <tr>
                    <td colspan="1" rowspan="1" class="">
                        (i) Surface water
                    </td>
                    <td colspan="1" rowspan="1" class="text-center">{surface}</td>

                    <td colspan="1" rowspan="1" class="text-center">{surface1}</td>


                </tr>
                <tr>
                    <td colspan="1" rowspan="1" class="">
                        (ii) Groundwater
                    </td>
                    <td colspan="1" rowspan="1" class="text-center">{ground}</td>

                    <td colspan="1" rowspan="1" class="text-center">{ground1}</td>



                </tr>
                <tr>
                    <td colspan="1" rowspan="1" class="">
                        (iii) Third party water
                    </td>
                    <td colspan="1" rowspan="1" class="text-center">{third}</td>
                    <td colspan="1" rowspan="1" class="text-center">{third1}</td>

                </tr>
                <tr>
                    <td colspan="1" rowspan="1" class="">
                        (iv) Seawater / desalinated water
                    </td>
                    <td colspan="1" rowspan="1" class="text-center">{sea}</td>
                    <td colspan="1" rowspan="1" class="text-center">{sea1}</td>

                </tr>
                <tr>
                    <td colspan="1" rowspan="1" class="">
                        (v) Others: Flush/ Recycled Water
                    </td>
                    <td colspan="1" rowspan="1" class="text-center">{other}</td>
                    <td colspan="1" rowspan="1" class="text-center">{other1}</td>

                </tr>
                <tr>
                    <td colspan="1" rowspan="1" class="">
                        Total volume of water withdrawal (in kilolitres)
                    </td>
                    <td colspan="1" rowspan="1" class="text-center">{total}</td>

                    <td colspan="1" rowspan="1" class="text-center">{total1}</td>


                </tr>
                <tr>
                    <td colspan="1" rowspan="1" class="">
                        Total volume of water consumption (in kilolitres)
                    </td>
                    <td colspan="1" rowspan="1" class="text-center">{ }</td>
                    <td colspan="1" rowspan="1" class="text-center">{ }</td>


                </tr>
            </>
        )

    }

    const WaterDisposal_247 = (yr) => {
        let surface = 0, ground = 0, third = 0, sea = 0, other = 0
        let surface2 = 0, ground2 = 0, third2 = 0, sea2 = 0, other2 = 0
        let surface3 = 0, ground3 = 0, third3 = 0, sea3 = 0, other3 = 0
        let surface1 = 0, ground1 = 0, third1 = 0, sea1 = 0, other1 = 0
        let submissions_cy = JSON.parse(JSON.stringify(response)).filter(i => i.dcfId === 247 && isDateInFiscalYear(yr, getRPTextFormat(i.reporting_period)))
        let submissions_py = JSON.parse(JSON.stringify(response)).filter(i => i.dcfId === 247 && isDateInFiscalYear(yr - 1, getRPTextFormat(i.reporting_period)))

        if (submissions_cy.length) {

            for (const item of submissions_cy) {
                console.log(item)
                item.response.forEach((dp) => {
                    let val = dp.DPAN0053A === 1 ? (parseFloat(dp.DPAN0053) / 1000) : dp.DPAN0053A === 2 ? parseFloat(dp.DPAN0053) : (parseFloat(dp.DPAN0053) * 1000)
                    if (dp.DPANN0030 === 1) {
                        if (dp.DPAN0052 === 1) {
                            surface += val
                        } else if (dp.DPAN0052 === 2) {
                            ground += val
                        } else if (dp.DPAN0052 === 3) {
                            sea += val
                        } else if (dp.DPAN0052 === 5) {
                            third += val
                        } else {
                            other += val
                        }
                    } else {
                        if (dp.DPAN0052 === 1) {
                            surface3 += val
                        } else if (dp.DPAN0052 === 2) {
                            ground3 += val
                        } else if (dp.DPAN0052 === 3) {
                            sea3 += val
                        } else if (dp.DPAN0052 === 5) {
                            third3 += val
                        } else {
                            other3 += val
                        }
                    }

                })
            }
        }
        if (submissions_py.length) {

            for (const item of submissions_py) {
                console.log(item)
                item.response.forEach((dp) => {
                    let val = dp.DPAN0053A === 1 ? (parseFloat(dp.DPAN0053) / 1000) : dp.DPAN0053A === 2 ? parseFloat(dp.DPAN0053) : (parseFloat(dp.DPAN0053) * 1000)
                    if (dp.DPANN0030 === 1) {

                        if (dp.DPAN0052 === 1) {
                            surface1 += val
                        } else if (dp.DPAN0052 === 2) {
                            ground1 += val
                        } else if (dp.DPAN0052 === 3) {
                            sea1 += val
                        }
                        else if (dp.DPAN0052 === 5) {
                            third1 += val
                        } else {
                            other1 += val
                        }
                    } else {
                        if (dp.DPAN0052 === 1) {
                            surface2 += val
                        } else if (dp.DPAN0052 === 2) {
                            ground2 += val
                        } else if (dp.DPAN0052 === 3) {
                            sea2 += val
                        }
                        else if (dp.DPAN0052 === 5) {
                            third2 += val
                        } else {
                            other2 += val
                        }
                    }
                })
            }
        }
        console.log(surface, ground, third, sea, other)
        let total = surface + ground + third + sea + other + surface3 + ground3 + third3 + sea3 + other3
        let total1 = surface1 + ground1 + third1 + sea1 + other1 + surface2 + ground2 + third2 + sea2 + other2
        return (
            <>
                <tr>
                    <td colspan="1" rowspan="1" class="">
                        (i) To Surface water
                    </td>
                    <td colspan="1" rowspan="1" class="">

                    </td>

                    <td colspan="1" rowspan="1" class="">

                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        -     No treatment
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {surface}
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {surface1}
                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        -     With  treatment  –  please  specify  level  of
                        treatment
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {surface3}
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {surface2}
                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        (ii) To Groundwater
                    </td>
                    <td colspan="1" rowspan="1" class="">

                    </td>
                    <td colspan="1" rowspan="1" class="">

                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        -     No treatment
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {ground}
                    </td>

                    <td colspan="1" rowspan="1" class="">
                        {ground1}
                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        -     With  treatment  –  please  specify  level  of
                        treatment
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {ground3}
                    </td>

                    <td colspan="1" rowspan="1" class="">
                        {ground2}
                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        (iii) To Seawater
                    </td>
                    <td colspan="1" rowspan="1" class="">

                    </td>

                    <td colspan="1" rowspan="1" class="">

                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        -     No treatment
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {sea}
                    </td>

                    <td colspan="1" rowspan="1" class="">
                        {sea1}
                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        -     With  treatment  –  please  specify  level  of
                        treatment
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {sea3}
                    </td>

                    <td colspan="1" rowspan="1" class="">
                        {sea2}
                    </td>

                </tr>


                <tr>
                    <td colspan="1" rowspan="1" class="">
                        (iv) Sent to third-parties
                    </td>
                    <td colspan="1" rowspan="1" class="">

                    </td>

                    <td colspan="1" rowspan="1" class="">

                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        -     No treatment
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {third}
                    </td>

                    <td colspan="1" rowspan="1" class="">
                        {third1}
                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        -     With  treatment  –  please  specify  level  of
                        treatment
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {third3}
                    </td>

                    <td colspan="1" rowspan="1" class="">
                        {third2}
                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        (v) Others
                    </td>
                    <td colspan="1" rowspan="1" class="">

                    </td>

                    <td colspan="1" rowspan="1" class="">

                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        -     No treatment
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {other}
                    </td>

                    <td colspan="1" rowspan="1" class="">
                        {other1}
                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        -     With  treatment  –  please  specify  level  of
                        treatment
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {other3}
                    </td>

                    <td colspan="1" rowspan="1" class="">
                        {other2}
                    </td>

                </tr>

                <tr>
                    <td colspan="1" rowspan="1" class="">
                        Total water discharged (in kilolitres)
                    </td>
                    <td colspan="1" rowspan="1" class="">
                        {total}
                    </td>

                    <td colspan="1" rowspan="1" class="">
                        {total1}
                    </td>

                </tr>
            </>
        )

    }

    const isMergedCell = (merge, rowIndex, colIndex) => {
        return merge.some((range) => rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c);
    };
    const getSum = (subset) => {
        let i = 0
        subset.forEach((item) => {
            i = i + item[1]
        })
        return i
    }
    const checkReportingPeriod = (rp, filter) => {

        let count = 0, rps = []
        filter.forEach((item) => {

            if (rp.includes(item)) {
                count = count + 1
                rps.push(item)
            }
        })
        return { result: count === rp.length, count: count, rps }
    }
    function getObjectsWithEmptyString(arr) {
        return arr
            .map((obj) => {
                const updatedObj = { ...obj };
                Object.keys(updatedObj).forEach((key) => {
                    if (typeof updatedObj[key] === "string" && updatedObj[key].trim() === "") {
                        delete updatedObj[key];
                    }
                });
                return updatedObj;
            })
            .filter((obj) => Object.values(obj).some((value) => value !== ""));
    }
    const getCellColSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.c - range.s.c + 1;
            }
        }
        return 1;
    };

    const getCellRowSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.r - range.s.r + 1;
            }
        }
        return 1;
    };
    function findValueByKey(object, key) {
        if (key in object) {
            return object[key];
        } else {
            return null; // or any other default value you want to return if the key is not found
        }
    }
    const getLastResponseByRFID = (key, uid) => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, key);
        console.log(key, result)
        if (result) {
            if (result[0].type === 0) {
                if (typeof uid === "string") {
                    let index = result[0].response.findIndex((k) => {
                        return k.name === uid;
                    });
                    if (index !== -1) {
                        if (result[0].response[index].type === 2) {

                            let value_2 = result[0].response[index].value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
                            return value_2 === null ? '' : result[0].response[index].value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')

                        } else if (result[0].response[index].type === 6 || result[0].response[index].type === 4) {
                            let rflib = rflibrary.findIndex((m) => { return m.id === key })
                            console.log(rflib, key, uid)
                            if (rflib !== -1) {
                                let field = rflibrary[rflib].data1.findIndex((s) => { return s.name === uid })
                                if (field !== -1) {
                                    if (typeof result[0].response[index].value[0] === 'number') {
                                        return rflibrary[rflib].data1[field].values[result[0].response[index].value[0]].label
                                    } else if (typeof result[0].response[index].value[0] === 'string') {

                                        let rgloc = rflibrary[rflib].data1[field].values.findIndex((l) => { return l.value === result[0].response[index].value[0] })
                                        if (rgloc !== -1) {
                                            return rflibrary[rflib].data1[field].values[rgloc].label
                                        }
                                    } else {
                                        return null
                                    }


                                } else {
                                    return 'Field Not Found'
                                }

                            } else {
                                return 'RF Not Found'
                            }
                        } else if (result[0].response[index].type === 3) {
                            let rflib = rflibrary.findIndex((m) => { return m.id === key })
                            console.log(rflib, key, uid)
                            if (rflib !== -1) {
                                let field = rflibrary[rflib].data1.findIndex((s) => { return s.name === uid })
                                if (field !== -1) {
                                    if (typeof result[0].response[index].value[0] === 'number') {
                                        return null
                                    } else if (typeof result[0].response[index].value[0] === 'string') {

                                        let rgloc = rflibrary[rflib].data1[field].values.findIndex((l) => { return l.value === result[0].response[index].value })
                                        if (rgloc !== -1) {
                                            return rflibrary[rflib].data1[field].values[rgloc].label
                                        }
                                    } else {
                                        return null
                                    }


                                } else {
                                    return 'Field Not Found'
                                }

                            } else {
                                return 'RF Not Found'
                            }
                        }
                        else if (result[0].response[index].type === 9) {
                            return DateTime.fromISO(result[0].response[index].value, { zone: 'utc' }).toFormat('dd-MM-yyyy')
                        } else {
                            return result[0].response[index].value;
                        }

                    } else {
                        return 'NA';
                    }
                } else {
                    let str = "";
                    uid.forEach((id) => {
                        let index = result[0].response.findIndex((k) => {
                            return k.name === id;
                        });
                        if (index !== -1) {

                            str = str + " " + result[0].response[index].value;
                        }
                    });
                    if (str.trim().length !== 0) {
                        return str;
                    } else {
                        return 'NA';
                    }
                }
            } else {
                return result[0].data2.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
            }
        }
        return "";
    };
    const updateDataByYear = (val) => {
        setYear(val);
        forceUpdate();
    };

    useEffect(() => {

        const elements = Array.from(document.querySelectorAll("sectionheader1,sectionheader")).map((elem) => ({
            id: elem.id,
            text: elem.childNodes[0].textContent.trim(),
            level: Number(elem.nodeName.charAt(13)),
            // level:1
        }));

        let as = [],
            indx = 0;
        elements.forEach((item, ind) => {
            if (item.level === 0) {
                as[indx] = item;
                indx = indx + 1;
            } else if (elements[ind - 1].level === 0) {
                as[indx] = { item: [item], level: 1 };
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            } else {
                as[indx].item.push(item);
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            }
        });

        setHeadings(as);
    }, []);

 
    
    
    const groupArrayObject = (array, obj) => {
        return array.reduce(
            (group, arr) => {
                let key = arr[obj];

                group[key] = group[key] ?? [];

                group[key].push(arr);

                return group;
            },

            {}
        );
    };
    useEffect(() => {
        let gtaString = {
            "include": ["newTargetsTwos", "newIndicatorTwos", "newInitiatives"]
        }
        let dcf_list = [], dcf_submitted = [], locloc = []
        let category_string = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }
        let ef_complete = API.EF_Std + `?filter=%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfDates%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfs%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfItems%22%20%7D%5D%20%7D%20%7D%5D%20%7D%20%7D%5D%7D`
        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(API.QN_Submit_UP(selector.id))
        const promise3 = APIServices.get(API.DCF_Entity_User_UP(selector.id))
        const promise4 = APIServices.get(ef_complete)
        const promise5 = APIServices.get(API.EF_SC1)
        const promise6 = APIServices.get(API.EF_SC2)
        const promise7 = APIServices.get(API.EF_SC3)
        const promise8 = APIServices.get(API.EF_SC4)
        Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8]).then(function (values) {
            setApiEF(values[3].data)
            setDCFAss(values[2].data.filter((k) => { return dcf_id.includes(k.dcfId) }).map((k) => { return { dcfId: k.dcfId } }))
            dcf_list = values[0].data; setDcfList(values[0].data)
            dcf_submitted = values[1].data.filter(i => { return hardcoded.dcf.includes(i.dcfId.toString()) && (i.type === 1 || i.type === 2) })
            console.log(dcf_submitted)


            let loc_subcat = subcat
            loc_subcat.one = values[4].data
            loc_subcat.two = values[5].data
            loc_subcat.three = values[6].data
            loc_subcat.four = values[7].data
            setSubCat(loc_subcat)

            setResponse(dcf_submitted)
            // let val = [], filterarr = groupArrayObject(values[2].data, 'submitId')
            // Object.keys(filterarr).forEach((item) => {

            //     val.push({ id: filterarr[item][0].submitId, rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], 'form_id')) })

            // })



            forceUpdate()

        })

    }, []);
    useEffect(() => {
        if (response.length !== 0 && subcat.one.length) {
            console.log(response)

            let rf = renderEF_New(subcat, response, year)



            let report_ = rf
            let reportEF_ = rf
            console.log(rf)
            // report_[`${year - 1}`] = renderEF_New(subcat,response, year)
            setReport(report_)
            setReportEF(reportEF_)
            forceUpdate()
        }
    }, [year])


    const renderEF_New = (locsubcat, response_, year) => {
        let report = []

        let filterResponse = response_.filter((i) => { return isInIndianFY(i.reporting_period, year) })
        console.log(filterResponse)
        filterResponse.forEach((item) => {
            report.push(...getEmissionFactorCalculation(item, year, locsubcat))
        })
        // for (let i = 0; i < filterRepsonse.length; i++) {     
        //    let result =await new Promise((resolve,reject) => {
        //     try{
        // resolve(getEmissionFactorCalculation(filterRepsonse[i],year,locsubcat))
        //     }catch(e){
        //         reject([])
        //     }
        //    })   
        //    console.log(result)
        //     }

        console.log(response)
        return report
    }



    const getScopeData = (year, area) => {
        if (report[year]) {
            return report[year].filter((k) => { return k.scope === area }).map((j) => { return j.ghg }).reduce((a, b) => { return a + b }, 0).toFixed(2)

        }
        return 0
    }
    const checkYear = (rp, yr) => {
        let betweenMonths = []



        let endDate = moment.utc(getDateObjectByMonth_Year(2, yr + 1)).local()
        let startDate = moment.utc(getDateObjectByMonth_Year(2, yr)).local()
        while (startDate.startOf('month') <= endDate.startOf('month')) {

            betweenMonths.push(startDate.format('MM-YYYY'));
            startDate.add(1, 'month');

        }
        return betweenMonths.filter((i) => { return rp.includes(i) }).length === rp.length ? 1 : betweenMonths.filter((i) => { return rp.includes(i) }).length

    }
    const isInIndianFY = (months, year) => {
        const startMonth = 3; // April (zero-based index)
        const endMonth = 2;   // March (zero-based index)
        const startYear = year - 1;
        const endYear = year;

        const startDate = new Date(startYear, startMonth, 1);
        const endDate = new Date(endYear, endMonth, 1);

        const isInFY = months.every(month => {
            const [m, y] = month.split('-');
            const date = new Date(parseInt(y), parseInt(m) - 1, 1); // Subtract 1 from month since it's zero-based
            return startDate <= date && date <= endDate;
        });

        return isInFY;
    }
    const getDataByDP = (dpid, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = 0

        dpreport_.filter((i) => { return i.dp === dpid }).forEach((k) => {


            if (checkYear(k.rp, yr) !== 0) {
                d = d + k.value
            }

        })

        return d
    }
    const checkScope = (arr) => {
        console.log(dcfass)
        let index = dcfass.findIndex((l) => { return arr.includes(l.dcfId) })
        return index !== -1 ? true : arr.includes(292) ? true : false
    }
    const getScopeDataFromResponse = (dcfId) => {
        console.log(report)
        return report.filter((l) => { return l.dcfId === dcfId })
    }
    function concatenateArrayWithAnd(array) {
        if (array.length === 1) {
            return array[0];
        } else if (array.length > 1) {
            const lastElement = array.pop(); // Remove the last element
            return `${array.join(', ')} and ${lastElement}`;
        } else {
            return 'NA'; // Return an empty string if the array is empty
        }
    }
    const getMCFuelUsed = () => {
        let result = []

        report.filter((l) => { return l.dcfId === 15 }).forEach((i, j) => {
            !result.includes(i.fuel_type) && result.push(i.fuel_type)
        })

        return concatenateArrayWithAnd(result)
    }
    const getSCFuelUsed = () => {
        let result = []

        report.filter((l) => { return l.dcfId === 11 }).forEach((i, j) => {
            !result.includes(i.fuel_type) && result.push(i.fuel_type)
        })
        return concatenateArrayWithAnd(result)
    }
    const getFEGasUsed = () => {
        let result = []

        report.filter((l) => { return l.dcfId === 10 }).forEach((i, j) => {
            result.push(i.gas_type)
        })
        return concatenateArrayWithAnd(result)
    }
    function removeDuplicatesByProperties(arr, keys) {
        const seen = new Set();
        return arr.filter(item => {
            const key = JSON.stringify(keys.map(key => item[key]));
            if (!seen.has(key)) {
                seen.add(key);
                return true;
            }
            return false;
        });
    }
    const renderFEGas = () => {
        let result = []

        reportEF.filter((l) => { return l.dcfId === 10 }).forEach((i, j) => {
            result.push({ type: i.gastype, ghg: (i.co2e_).toFixed(2) + ' kg CO2e/kg' })
        })

        return result.length === 0 ? [{ type: 'Not Found', ghg: 0 }] : result
    }
    const renderSCFuel = () => {
        let result = []

        reportEF.filter((l) => { return l.dcfId === 11 }).forEach((i, j) => {

            result.push({ type: i.fuel_type + '-' + i.unit, co2_: (i.co2_).toFixed(2) + ' kg CO2e/kg', n2o_: (i.n2o_).toFixed(2) + ' kg CO2e/kg', ch4_: (i.ch4_).toFixed(2) + ' kg CO2e/kg' })
        })
        return result.length === 0 ? [{ type: 'Not Found', co2_: 0, ch4_: 0, n2o_: 0 }] : removeDuplicatesByProperties(result, [
            'co2_',
            'n2o_',
            'ch4',
            'unit',
            'fuel_type',
        ])
    }

    const renderMCFuel = () => {
        let result = []

        reportEF.filter((l) => { return l.dcfId === 15 }).forEach((i, j) => {
            console.log('i', i)
            result.push({ type: i.mode + ' - ' + i.fuel_cat, ghg: (i.co2e_).toFixed(2) + ' kg CO2e /litre' })
        })

        return result.length === 0 ? [{ type: 'Not Found', ghg: 0 }] : result
    }
    const getScopeDataByDCF = (id) => {
        console.log(report)
        let report_ = JSON.parse(JSON.stringify(report)).filter((i) => { return id.includes(i.dcfId) }).map((i) => { return i.ghg }).reduce((a, b) => { return a + b }, 0)
        return report_.toFixed(3)
    }
    const renderEmissionProfileTable = (text) => {

        return (
            <div class="gridlines-container">
                <table class="gridlines">
                    <tbody>

                        <tr>
                            <td colspan="1" rowspan="1" className="tdHead">{text}
                            </td>
                            <td colspan="1" rowspan="1" className="tdHead">tCO2e
                            </td>
                        </tr>
                        {checkScope([11, 10,]) &&
                            <>

                                {/* <tr>
                                    <td colspan="1" rowspan="1" >Scope 1- Direct Emissions
                                    </td>
                                    <td colspan="1" rowspan="1" >{getScopeDataByDCF([11, 10, 15])}
                                    </td>
                                </tr> */}

                                {checkScope([11]) &&
                                    <tr>
                                        <td colspan="1" rowspan="1" >Stationary Combustion
                                        </td>
                                        <td colspan="1" rowspan="1" > {getScopeDataByDCF([11])}
                                        </td>
                                    </tr>
                                }
                                {/* {checkScope([15]) &&
                                    <tr>
                                        <td colspan="1" rowspan="1" >Mobile Combustion (Owned Vehicles)
                                        </td>
                                        <td colspan="1" rowspan="1" > {getScopeDataByDCF([15])}
                                        </td>
                                    </tr>
                                } */}
                                {checkScope([10]) &&
                                    <tr>
                                        <td colspan="1" rowspan="1" >Fugitive Emissions 
                                        </td>
                                        <td colspan="1" rowspan="1" > {getScopeDataByDCF([10])}
                                        </td>
                                    </tr>
                                }
                            </>}
                        {checkScope([257]) &&
                            <>
                                <tr>
                                    <td colspan="1" rowspan="1" >Scope 2- GHG emissions
                                    </td>
                                    <td colspan="1" rowspan="1" > {getScopeDataByDCF([257])}
                                    </td>
                                </tr>

                                {/* <tr>
                                    <td colspan="1" rowspan="1" >Emissions from purchased energy (Grid Electricity)
                                    </td>
                                    <td colspan="1" rowspan="1" > {getScopeDataByDCF([257])}
                                    </td>
                                </tr> */}
                            </>
                        }
                       
                        <tr>
                            <td colspan="1" rowspan="1" >Total Emission
                            </td>
                            <td colspan="1" rowspan="1" >
                                {getScopeDataByDCF(dcf_id)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
    const renderEmissionByScopeTable = (text) => {

        return (
            <div class="gridlines-container">
                <table class="gridlines">
                    <tbody>

                        <tr>
                            <td colspan="1" rowspan="1" className="tdHead" >Scope of Emission
                            </td>
                            <td colspan="1" rowspan="1" className="tdHead">tCO2e
                            </td>
                        </tr>
                        {checkScope([11, 10, 15]) &&
                            <>

                                <tr>
                                    <td colspan="1" rowspan="1" >Scope 1- Direct Emissions
                                    </td>
                                    <td colspan="1" rowspan="1" >{getScopeDataByDCF([11, 10, 15])}
                                    </td>
                                </tr>


                            </>}
                        {checkScope([72]) &&

                            <tr>
                                <td colspan="1" rowspan="1" >Scope 2- Indirect Emission
                                </td>
                                <td colspan="1" rowspan="1" > {getScopeDataByDCF([72])}
                                </td>
                            </tr>



                        }
                        {checkScope([16, 36]) &&
                            <tr>
                                <td colspan="1" rowspan="1" >Scope 3- Indirect emissions
                                </td>
                                <td colspan="1" rowspan="1" >{getScopeDataByDCF([16, 36])}
                                </td>
                            </tr>

                        }

                    </tbody>
                </table>
            </div>
        )
    }
    const renderEmissionByCategoryTable = () => {

        return (
            <div class="gridlines-container">
                <table class="gridlines">
                    <tbody>

                        <tr>
                            <td colspan="1" rowspan="1" className="tdHead" >Emission Profile
                            </td>
                            <td colspan="1" rowspan="1" className="tdHead">tCO2e
                            </td>
                        </tr>
                        {checkScope([11, 10, 15]) &&
                            <>



                                {checkScope([11]) &&
                                    <tr>
                                        <td colspan="1" rowspan="1" >Stationary Combustion (Fuel Used)
                                        </td>
                                        <td colspan="1" rowspan="1" > {getScopeDataByDCF([11])}
                                        </td>
                                    </tr>
                                }
                                {checkScope([15]) &&
                                    <tr>
                                        <td colspan="1" rowspan="1" >Mobile Combustion (Owned Vehicles)
                                        </td>
                                        <td colspan="1" rowspan="1" > {getScopeDataByDCF([15])}
                                        </td>
                                    </tr>
                                }
                                {checkScope([10]) &&
                                    <tr>
                                        <td colspan="1" rowspan="1" >Fugitive Emissions (Refrigerants)
                                        </td>
                                        <td colspan="1" rowspan="1" > {getScopeDataByDCF([10])}
                                        </td>
                                    </tr>
                                }
                            </>}
                        {checkScope([72]) &&
                            <>


                                <tr>
                                    <td colspan="1" rowspan="1" >Emissions from purchased energy (Grid Electricity)
                                    </td>
                                    <td colspan="1" rowspan="1" > {getScopeDataByDCF([72])}
                                    </td>
                                </tr>
                            </>
                        }
                        {checkScope([16, 36]) && <>

                            {checkScope([16]) &&
                                <tr>
                                    <td colspan="1" rowspan="1">Purchased Goods and Services
                                    </td>
                                    <td colspan="1" rowspan="1" >{getScopeDataByDCF([16])}
                                    </td>
                                </tr>
                            }
                            {checkScope([36]) &&
                                <tr>
                                    <td colspan="1" rowspan="1" >Business Travel
                                    </td>
                                    <td colspan="1" rowspan="1" >{getScopeDataByDCF([36])}
                                    </td>
                                </tr>
                            }
                            {/* <tr>
                            <td colspan="1" rowspan="1" >Employee Commute
                            </td>
                            <td colspan="1" rowspan="1" >
                            </td>
                        </tr> */}
                        </>
                        }

                    </tbody>
                </table>
            </div>
        )
    }
    const getDataByDCFDPID = (dcfid, dpid, yr) => {
        let response_ = JSON.parse(JSON.stringify(response))
        let d = 0

        response_.forEach((k) => {
            if (k.dcf === dcfid) {

                if (checkYear(k.rp, yr) !== 0) {
                    let result = k.response.filter((k) => { return k.name === dpid })
                    if (result.length !== 0) {
                        d = d + parseInt(result[0].value.match(/\d+/)[0])
                    }
                }
            }
        })

        return d
    }

    function getDateObjectByMonth_Year(month, year) {

        if (isNaN(month) || isNaN(year)) {
            throw new Error('Invalid month or year');
        }

        const normalizedMonth = Math.max(1, Math.min(12, month));

        const date = DateTime.fromObject({ year, month: normalizedMonth, day: 1 });

        return date.toJSDate();
    }
    const getRP_ = (rp) => {
        if (rp.length === 1) {

            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2)
        } else {
            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2) + "to" + months_[parseInt(rp[rp.length - 1].split('-')[0]) - 1] + "-" + rp[rp.length - 1].split('-')[1].slice(-2)
        }
    }
    const getClassName = (level) => {
        switch (level) {
            case 1:
                return "head1";
            case 2:
                return "head2";
            case 3:
                return "head3";
            default:
                return null;
        }
    };

    const checkSite = (id, filter) => {
        let idlist = []

        siteList.forEach((country) => {

            if (filter.a.id === 0 || filter.a.id === country.id) {

                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id)
                            }
                        })
                    }
                })
            }
        })

        return idlist.includes(id)
    }
    const groupArrayObject_3_Keys = (array, obj1, obj2, obj3) => {
        return array.reduce((result, arr) => {
            let key1 = arr[obj1];
            let key2 = arr[obj2];
            let key3 = arr[obj3]

            const key = `${key1}-${key2}-${key3}`;

            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(arr);

            return result;
        }, {})
    }
    const renderData = (search) => {
        let betweenMonths = [], betweenYears = []
        let monthly_fg = []


        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')

        console.log(search)
        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));
                !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(response))

            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dp === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


                                let item_class = fg.filter((i) => { return i.dp === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dp === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dp === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {

                        // 22032
                        report.response.forEach((fg, ind) => {
                            console.log(fg)
                            let date = fg.filter((i) => { return i.dp === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {

                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dp === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dp === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dp === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dp === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dp === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dp === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dp === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dp === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dp === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dp === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dp === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dp === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_type === fuel_type })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dp === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dp === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o

                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o
                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2
                                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4
                                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_type === item.fuel_type })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_type: item.fuel_type, co2: (item.co2 * 1000) / addedMonth.count, n2o: (item.n20 * 1000) / addedMonth.count, ch4: (item.ch4 * 1000) / addedMonth.count })


                                } else {
                                    monthly_fg[updateind].co2 = (item.co2 * 1000) / addedMonth.count * 1000 + monthly_fg[updateind].co2
                                    monthly_fg[updateind].n2o = (item.n2o * 1000) / addedMonth.count * 1000 + monthly_fg[updateind].n2o
                                    monthly_fg[updateind].ch4 = (item.ch4 * 1000) / addedMonth.count * 1000 + monthly_fg[updateind].ch4
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dp === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {

                                let gastype = fg.filter((i) => { return i.dp === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dp === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dp === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dp === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dp === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dp === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dp === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dp === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dp === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dp === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dp === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.fuel === fuel })

                            if (mode === 'by distance') {

                                total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: fuel, ghg: total * km, fuel_cat: fuel_cat.name })
                                } else {

                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            } else {
                                total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: fuel, ghg: total * fuel_filled })
                                } else {

                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg

                                    } else {

                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode, fuel_cat: item.fuel_cat })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })





            let scope12_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1 || i.scope === 2) }), 'month')
            let scope3_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 3) }), 'month')
            let scope1_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1) }), 'month')
            let scope2_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 2) }), 'month')
            let pie = []
            monthly_fg.filter((i) => { return (i.scope === 1) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 2) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 3) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })

            let index1 = pie.findIndex((j) => { return j.name === 'Scope 1' })
            let index2 = pie.findIndex((j) => { return j.name === 'Scope 2' })
            let index3 = pie.findIndex((j) => { return j.name === 'Scope 3' })

            if (index1 === -1) {
                pie.push({ name: 'Scope 1', y: 0 })
            }
            if (index2 === -1) {
                pie.push({ name: 'Scope 2', y: 0 })
            }
            if (index3 === -1) {
                pie.push({ name: 'Scope 3', y: 0 })
            }


            if (scope1_array.length !== 0) {
                let scope1_tier2 = [], final = []
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {

                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })

                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })


                    final.push(dataset)
                })

            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [], final = []
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

                    final.push(dataset)
                })


            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [], final = []
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

                    final.push(dataset)
                })

            }



        }

        return monthly_fg


    }
    const renderDataEF = (search) => {
        let betweenMonths = [], betweenYears = []
        let monthly_fg = []

        console.log(search)

        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')


        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));
                !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(response))

            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dp === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


                                let item_class = fg.filter((i) => { return i.dp === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dp === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dp === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dp === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dp === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dp === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dp === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dp === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dp === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dp === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dp === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dp === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dp === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dp === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dp === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dp === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_type === fuel_type && k.unit === unit })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dp === "   " })[0].value
                                let cv = fg.filter((i) => { return i.dp === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4, unit, co2_: 0, n2o_: 0, ch4_: 0 })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o

                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    let co2_ = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'])
                                    let ch4_ = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'])
                                    let n2o_ = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'])
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, unit, co2, n2o, ch4, co2_, n2o_, ch4_ })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o
                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                let co2_ = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'])
                                let ch4_ = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'])
                                let n2o_ = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'])

                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4, unit, co2_, n2o_, ch4_ })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2
                                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4
                                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_type === item.fuel_type && i.unit === item.unit })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_type: item.fuel_type, unit: item.unit, co2: (item.co2 * 1000) / addedMonth.count, n2o: (item.n20 * 1000) / addedMonth.count, ch4: (item.ch4 * 1000) / addedMonth.count, co2_: item.co2_, ch4_: item.ch4_, n2o_: item.n2o_ })


                                } else {
                                    monthly_fg[updateind].co2 = (item.co2 * 1000) / addedMonth.count * 1000 + monthly_fg[updateind].co2
                                    monthly_fg[updateind].n2o = (item.n2o * 1000) / addedMonth.count * 1000 + monthly_fg[updateind].n2o
                                    monthly_fg[updateind].ch4 = (item.ch4 * 1000) / addedMonth.count * 1000 + monthly_fg[updateind].ch4
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dp === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {

                                let gastype = fg.filter((i) => { return i.dp === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dp === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled
                                let co2e_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg']
                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total, co2e_ })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dp === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dp === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, co2e_: ef, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dp === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dp === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dp === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dp === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dp === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dp === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dp === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.fuel === fuel })

                            if (mode === 'by distance') {

                                total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: fuel, ghg: total * km, co2e_: total, fuel_cat: fuel_cat.name })
                                } else {

                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            } else {

                                total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: fuel, ghg: total * fuel_filled, co2e_: total, fuel_cat: '' })
                                } else {

                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                        // monthly_fg[updateind2]['fuel_cat'] = item.fuel_cat
                                    } else {
                                        console.log('EF', item)
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode, fuel_cat: item.fuel_cat, co2e_: item.co2e_ })
                                    }




                                } else {
                                    // monthly_fg[updateind]['fuel_cat'] = item.fuel_cat
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })





            let scope12_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1 || i.scope === 2) }), 'month')
            let scope3_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 3) }), 'month')
            let scope1_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1) }), 'month')
            let scope2_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 2) }), 'month')
            let pie = []
            monthly_fg.filter((i) => { return (i.scope === 1) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 2) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 3) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })

            let index1 = pie.findIndex((j) => { return j.name === 'Scope 1' })
            let index2 = pie.findIndex((j) => { return j.name === 'Scope 2' })
            let index3 = pie.findIndex((j) => { return j.name === 'Scope 3' })

            if (index1 === -1) {
                pie.push({ name: 'Scope 1', y: 0 })
            }
            if (index2 === -1) {
                pie.push({ name: 'Scope 2', y: 0 })
            }
            if (index3 === -1) {
                pie.push({ name: 'Scope 3', y: 0 })
            }


            if (scope1_array.length !== 0) {
                let scope1_tier2 = [], final = []
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {

                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })

                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })


                    final.push(dataset)
                })

            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [], final = []
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

                    final.push(dataset)
                })


            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [], final = []
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

                    final.push(dataset)
                })

            }



        }

        return monthly_fg


    }
    const getBetweenMonths = (year) => {
        console.log(year)
        const months = [];
        // Start from April of the previous year
        let startMonth = DateTime.fromObject({ year: year - 1, month: 4 });
        // End in March of the given year
        const endMonth = DateTime.fromObject({ year, month: 3 });
        console.log(startMonth, endMonth)
        // Loop through each month
        while (startMonth <= endMonth) {
            const formattedMonth = startMonth.toFormat('MM-yyyy');
            console.log(formattedMonth)
            months.push(formattedMonth);
            // Move to the next month
            startMonth.plus({ months: 1 });
            startMonth = startMonth.plus({ months: 1 });
        }

        return months;
    }
    const isDateWithinPeriod = (rp, period) => {
        // Convert date strings to Luxon DateTime objects
        const rpDates = rp.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));
        const periodDates = period.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));

        // Find the matching dates in rp within the period
        const matchingDates = rpDates.filter(rpDate =>
            periodDates.some(periodDate => rpDate.toMillis() === periodDate.toMillis())
        );

        return matchingDates.length > 0 ? { match: true, dates: matchingDates.map(date => date.toFormat('MM-yyyy')) } : { match: false, dates: null };
    };
    const findIndexByDate = (array, dateToFind) => {
        const targetDate = DateTime.fromFormat(dateToFind, 'MM-yyyy');

        if (array.length === 1) {
            return 0; // If array length is 1, return index 0
        }

        let foundIndex = -1;

        // Check for an object with both start and end not null and matching the passed date
        const matchedObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start);
            const endDate = DateTime.fromISO(obj.end);

            return startDate <= targetDate && targetDate <= endDate;
        });

        if (matchedObject) {
            return array.indexOf(matchedObject);
        }

        // Check for an object with end as null and start date lesser than passed date
        const endIsNullObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start);
            return obj.end === null && startDate <= targetDate;
        });

        if (endIsNullObject) {
            return array.indexOf(endIsNullObject);
        }

        // If none of the above conditions match, find the object with the lesser start date
        let lesserStartDateObject = array.reduce((prev, curr) => {
            const prevStartDate = DateTime.fromISO(prev.start);
            const currStartDate = DateTime.fromISO(curr.start);
            return prevStartDate < currStartDate ? prev : curr;
        });

        return array.indexOf(lesserStartDateObject);
    };
    const getEmissionFactorCalculation = (item, year, locsubcat) => {
        let betweenMonths = getBetweenMonths(year)
        console.log(item)
        let month_data = []
        if (item.dcfId === 11) {
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            console.log(match, dates)
            if (match) {

                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                console.log('Matched_SC', standard_index)
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            console.log('Dates_SC')
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0130 && i.subcategory2 === sc.DPA0131 && i.subcategory3 === sc.DPA0132 })

                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0336) * ef.co2e) / dates.length) / 1000).toFixed(3))
                                            console.log(sc.DPA0336, ef)
                                            let fuel_type_index = locsubcat.two.findIndex((i) => { return i.id === sc.DPA0131 })
                                            let unit_index = locsubcat.three.findIndex((i) => { return i.id === sc.DPA0132 })
                                            let fuel_cat_index = locsubcat.one.findIndex((i) => { return i.id === sc.DPA0130 })

                                            let fuel_type = 'Other', usage = 0, r = 0, nr = 0
                                            if (fuel_type_index !== -1) {
                                                fuel_type = locsubcat.two[fuel_type_index].title
                                            }
                                            if (unit_index !== -1) {
                                                let unit_type = locsubcat.three[fuel_type_index].title

                                                if (unit_type.includes('ton')) {
                                                    if (fuel_cat_index !== -1) {
                                                        let cat_type = locsubcat.one[fuel_cat_index].title

                                                        if (cat_type.trim().toLowerCase().includes('bio')) {
                                                            r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))
                                                        } else {

                                                            nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))

                                                        }
                                                    }
                                                    usage = parseFloat((sc.DPA0336 / 0.81).toFixed(3))

                                                } else {
                                                    if (fuel_cat_index !== -1) {

                                                        let cat_type = locsubcat.one[fuel_cat_index].title
                                                        if (cat_type.trim().toLowerCase().includes('bio')) {
                                                            r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                        } else {

                                                            nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                        }
                                                    }
                                                    usage = parseFloat(sc.DPA0336)
                                                }
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.fuel_type === fuel_type })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], ef, scope: 'scope1', ghg, fuel_type, usage, r, nr, dcfId: item.dcfId })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                                month_data[month_index].usage += usage
                                                month_data[month_index].r += r
                                                month_data[month_index].nr += nr
                                            }

                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 10) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)
                                console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 5 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0136 })
                                        console.log(sc_index, sc.DPA0136, apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems)
                                        if (sc_index !== -1) {
                                            console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0138) * ef.co2e) / dates.length) / 1000).toFixed(3))
                                            console.log(sc.DPA0138, ef)
                                            let gas_type_index = locsubcat.one.findIndex((i) => { return i.id === sc.DPA0136 })
                                            let gas_type = 'Other'
                                            if (gas_type_index !== -1) {
                                                gas_type = locsubcat.one[gas_type_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.gas_type === gas_type })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, gas_type, usage: parseFloat(sc.DPA0138), dcfId: item.dcfId })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                                month_data[month_index].usage += parseFloat(sc.DPA0138)
                                            }

                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 16) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 11 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0287 })

                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0288) * parseFloat(sc.DPA0289) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                            let item_class_index = locsubcat.one.findIndex((i) => { return i.id === sc.DPA0287 })
                                            let item_class = 'Other'
                                            if (item_class_index !== -1) {
                                                item_class = locsubcat.one[item_class_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.item_class === item_class })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, item_class, dcfId: item.dcfId })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                            }

                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 15) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    if (!sc.DP_MODE) {
                                        let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                        console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                        if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                            let sc_index = -1
                                            if (sc.DPA0141 === 2) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 1 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 7 })
                                            } else if (sc.DPA0141 === 13) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 51 })
                                            } else if (sc.DPA0141 === 19) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 75 })

                                            }
                                            if (sc_index !== -1) {
                                                let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                                let ghg = parseFloat((((parseFloat(sc.DPA0143) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                                let fuel_type_index = locsubcat.two.findIndex((i) => { return i.id === sc.DPA0141 })
                                                let fuel_type = 'Other'
                                                if (fuel_type_index !== -1) {
                                                    fuel_type = locsubcat.two[fuel_type_index].title
                                                }
                                                let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.type === 'byFuel' && i.fuel_type === fuel_type })
                                                if (month_index === -1) {
                                                    month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, fuel_type, type: 'byFuel', dcfId: item.dcfId })
                                                } else {
                                                    month_data[month_index].ghg += ghg
                                                }

                                            }

                                        }
                                    } else {
                                        let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === sc.DPGMode })
                                        console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                        if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                            let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0140 && i.subcategory2 === sc.DPA0139 && i.subcategory3 === sc.DPA0141 && i.subcategory4 === sc.DPA0339 })

                                            if (sc_index !== -1) {
                                                let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                                let ghg = parseFloat((((parseFloat(sc.DPA0144) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                                let fuel_type_index = locsubcat.two.findIndex((i) => { return i.id === sc.DPA0139 })
                                                let fuel_type = 'Other'
                                                if (fuel_type_index !== -1) {
                                                    fuel_type = locsubcat.two[fuel_type_index].title
                                                }
                                                let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.fuel_type === fuel_type && i.type === 'byDistance' })
                                                if (month_index === -1) {
                                                    month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, fuel_type, type: 'byDistance', dcfId: item.dcfId })
                                                } else {
                                                    month_data[month_index].ghg += ghg
                                                }

                                            }

                                        }
                                    }

                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 257) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {

                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)
                                console.log(sc, item.id)
                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i, j) => { return j === 0 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN095 && i.subcategory2 === sc.DPAN096 && i.subcategory3 === sc.DPAN099 })
                                        console.log(sc_index)
                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = 0

                                            console.log(sc.DPA0138, ef)
                                            let source_index = locsubcat.two.findIndex((i) => { return i.id === sc.DPAN096 })
                                            let type_index = locsubcat.one.findIndex((i) => { return i.id === sc.DPAN095 })
                                            let type = 'Other', source_type = 'Other', r = 0, nr = 0
                                            if (type_index !== -1) {
                                                type = locsubcat.one[type_index].title
                                            }
                                            if (source_index !== -1) {

                                                source_type = locsubcat.two[source_index].title
                                                console.log(source_type, sc.DPAN096)
                                                if (source_type.trim().toLowerCase().includes('non')) {
                                                    ghg = parseFloat((((parseFloat(sc.DPAN098) * ef.co2e) / dates.length) / 1000).toFixed(3))
                                                    nr = parseFloat(sc.DPAN098 / 1000)
                                                } else {
                                                    r = parseFloat(sc.DPAN098 / 1000)
                                                }
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 2 && i.source_type === source_type && i.type === type })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 2, ghg, type, source_type, r, nr, dcfId: item.dcfId })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                                month_data[month_index].r += r
                                                month_data[month_index].nr += nr
                                            }

                                        } else {

                                            if (locsubcat.one.map(i => i.id).includes(sc.DPAN095) && locsubcat.two.map(i => i.id).includes(sc.DPAN096) && locsubcat.three.map(i => i.id).includes(sc.DPAN099)) {
                                                let source_type = '', r = 0, nr = 0, type = ''
                                                let source_index = locsubcat.two.findIndex((i) => { return i.id === sc.DPAN096 })
                                                let type_index = locsubcat.one.findIndex((i) => { return i.id === sc.DPAN095 })
                                                type = locsubcat.one[type_index].title
                                                source_type = locsubcat.two[source_index].title
                                                if (!source_type.trim().toLowerCase().includes('non')) {
                                                    r = parseFloat(sc.DPAN098 / 1000)
                                                }
                                                let month_index = month_data.findIndex((i) => { return i.scope === 2 && i.source_type === source_type && i.type === type })
                                                if (month_index === -1) {
                                                    month_data.push({ year: rpm.split('-')[1], month: rpm, ef: {}, scope: 2, ghg: 0, type, source_type, r, nr, dcfId: item.dcfId })
                                                } else {
                                                    month_data[month_index].ghg += 0
                                                    month_data[month_index].r += r
                                                    month_data[month_index].nr += nr
                                                }
                                            }
                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 36) {
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (sc.DPA0291 === 'Road') {
                            if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                                let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                                console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 6 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0295 && i.subcategory2 === sc.DPA0337 && i.subcategory3 === sc.DPA0338 && i.subcategory4 === sc.DPA0338G })
                                        console.log(sc_index, apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems)
                                        if (sc_index !== -1) {
                                            console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0292) * ef.co2e)) / 1000).toFixed(3))




                                            let month_index = month_data.findIndex((i) => { return i.scope === 'scope3' && i.mode === 'Road' })
                                            if (month_index !== -1) {
                                                month_data[month_index].ghg += ghg
                                                month_data[month_index].passenger += parseFloat(sc.DPA0292)
                                            } else {
                                                month_data.push({ year: item.reporting_period[0].split('-')[1], ef, mode: 'Road', scope: 'scope3', ghg, passenger: parseFloat(sc.DPA0292) })
                                            }

                                        }

                                    }
                                }


                            }
                        } else if (sc.DPA0291 === 'Air') {

                            let ghg = sc.DP_co2e_mt
                            let month_index = month_data.findIndex((i) => { return i.scope === 'scope3' && i.mode === 'Air' })

                            if (month_index !== -1) {
                                month_data[month_index].ghg += ghg
                                month_data[month_index].passenger += parseFloat(sc.DPA0292)
                            } else {
                                month_data.push({ year: item.reporting_period[0].split('-')[1], mode: 'Air', scope: 'scope3', ghg, passenger: parseFloat(sc.DPA0292) })
                            }
                        } else if (sc.DPA0291 === 'Rail') {

                            let ghg = parseFloat(sc.DPA0292) * sc.DP_KM * 0.00116
                            let month_index = month_data.findIndex((i) => { return i.scope === 'scope3' && i.mode === 'Rail' })

                            if (month_index !== -1) {
                                month_data[month_index].ghg += ghg
                                month_data[month_index].passenger += parseFloat(sc.DPA0292)
                            } else {
                                month_data.push({ year: item.reporting_period[0].split('-')[1], mode: 'Rail', scope: 'scope3', ghg, passenger: parseFloat(sc.DPA0292) })
                            }
                        }

                    })
                }
            }
        } else if (item.dcfId === 292) {
            console.log(item)
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {

                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 42 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1
                                        if (sc.DPAN1150 === 3) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1151 && i.subcategory2 === sc.DPAN1152 && i.subcategory3 === sc.DPAN1155 })
                                        } else {
                                            if (sc.DPAN1151 === 316 || sc.DPAN1151 === 317) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1151 && i.subcategory2 === sc.DPAN1152 && i.subcategory3 === sc.DPAN1154 && i.subcategory4 === sc.DPAN1155 })
                                            } else {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1151 && i.subcategory2 === sc.DPAN1152 && i.subcategory3 === sc.DPAN1153 && i.subcategory4 === sc.DPAN1155 })
                                            }
                                        }

                                        const mode_options = [{ name: 'Road', id: 1 }, { name: 'Air', id: 2 }, { name: 'Rail', id: 3 }, { name: 'Sea', id: 4 }]
                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPAN1156) * parseFloat(sc.DPAN1157) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                            let mode_index = mode_options.findIndex((i) => { return i.id === sc.DPAN1150 })
                                            let mode = 'Other'
                                            if (mode_index !== -1) {
                                                mode = mode_options[mode_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.mode === mode })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, mode, dcfId: item.dcfId })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                            }

                                        }

                                    }


                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 293) {
            console.log(item)
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {

                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 42 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1
                                        if (sc.DPAN1208 === 3) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1209 && i.subcategory2 === sc.DPAN1210 && i.subcategory3 === sc.DPAN1213 })
                                        } else {
                                            if (sc.DPAN1209 === 316 || sc.DPAN1209 === 317) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1209 && i.subcategory2 === sc.DPAN1210 && i.subcategory3 === sc.DPAN1212 && i.subcategory4 === sc.DPAN1213 })
                                            } else {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1209 && i.subcategory2 === sc.DPAN1210 && i.subcategory3 === sc.DPAN1211 && i.subcategory4 === sc.DPAN1213 })
                                            }
                                        }

                                        const mode_options = [{ name: 'Road', id: 1 }, { name: 'Air', id: 2 }, { name: 'Rail', id: 3 }, { name: 'Sea', id: 4 }]
                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPAN1214) * parseFloat(sc.DPAN1215) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                            let mode_index = mode_options.findIndex((i) => { return i.id === sc.DPAN1208 })
                                            let mode = 'Other'
                                            if (mode_index !== -1) {
                                                mode = mode_options[mode_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 'scope3' && i.mode === mode })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope3', ghg, mode, dcfId: item.dcfId })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                            }

                                        }

                                    }


                                }

                            })
                        }
                    })
                }
            }

        }
        console.log(month_data, item.dcfId)
        return month_data
    }

    const showSite = () => {
        let site_ids = [], site_names = []
        // dcfass.forEach((i) => {
        //     !site_ids.includes(i.site) && site_ids.push(i.site)
        // })

        site_ids.forEach((i, j) => {
            site_names.push(locationList.find((k) => { return k.id === i }).title)

        })

        return site_names
    }


    return (
        <div>
            {/* <img id='page1' src={require('../reports/assets/cfp_image.png').default} width={'100%'} hidden /> */}
            <img id='page1' src={require('../reports/assets/csrd_img.png').default} width={'50%'} hidden />
            <img id='page2' src={require('../reports/assets/csrd_1.png').default} width={'50%'} hidden />

            <div className="col-12" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                {/* nav bar     */}
                <div className="col-3 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll" }}>
                    <nav>
                        {headings.map((heading, ind) => {
                            let indexes = [];
                            return (
                                <>
                                    {heading.level === 0 ? (
                                        <label key={heading.id} style={{ display: "flex", margin: 5, fontWeight: activeId === heading.id ? "bold" : "normal", textDecoration: heading.text.includes("SECTION") && "underline" }} className={getClassName(heading.level)}>
                                            <a
                                                href={`#${heading.id}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    document.querySelector(`#${heading.id}`).scrollIntoView({
                                                        behavior: "smooth",
                                                        block: "start",
                                                        inline: "nearest",
                                                    });
                                                }}
                                                style={{
                                                    fontWeight: activeId === heading.id ? "bold" : "normal",
                                                }}
                                            >
                                                {heading.text}
                                            </a>




                                        </label>
                                    ) : (
                                        <ul>
                                            {heading.item.map((item, ind2) => {
                                                return (
                                                    <li key={item.id} className={getClassName(item.level)}>
                                                        <a
                                                            href={`#${item.id}`}
                                                            // onClick={(e) => {
                                                            //     e.preventDefault();
                                                            //     document.querySelector(`#${item.id}`).scrollIntoView({
                                                            //         behavior: "smooth",
                                                            //     });
                                                            // }}
                                                            style={{
                                                                fontWeight: activeId === item.id ? "bold" : "normal",
                                                            }}
                                                        >
                                                            {item.text}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </>
                            );
                        })}
                    </nav>
                </div>

                <div className="col-9 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll", color: "white" }}>
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'flex-end' }} >

                        <Button icon='pi pi-eye' style={{ marginRight: 10 }} rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(1) }}> </Button>

                        <Button icon='pi pi-cloud-download' rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(0) }}> </Button>



                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* <img id='ass' src='https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/hazards/1690426362525Pushing%20and%20Pulling.png' width={'100%'} /> */}
                        <div>
                            <div className="col-12 grid" style={{ margin: 5, color: "white" }}>
                                <div>
                                    <label
                                        style={{
                                            color: "black",
                                            padding: 15,
                                            justifyContent: "flex-start",
                                            display: "flex",
                                        }}
                                    >
                                        Select Year :
                                    </label>
                                </div>
                                <div className="col-4">
                                    <Dropdown options={[{ name: 2024 }]} value={year} optionLabel="name" optionValue="name" onChange={(e) => { setYear(e.value) }} />
                                </div>
                            </div>
                            {/* <input type="file" onChange={handleFileChange} /> */}
                            {/* {tableData.length > 0 && (
                                <div className="gridlines-container">
                                    <table className="gridlines">
                                        <thead>
                                            <tr>
                                                {Object.keys(tableData[0]).map((field, index) => (
                                                    <th key={index} colSpan={getCellColSpan(0, index)}>
                                                        {field}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {Object.keys(row).map((cellRef, colIndex) => {
                                                        const cellValue = row[cellRef];
                                                        const colSpan = getCellColSpan(rowIndex + 1, colIndex); // Increment rowIndex to exclude header
                                                        const rowSpan = getCellRowSpan(rowIndex + 1, colIndex); // Increment rowIndex to exclude header
                                                        return (
                                                            <td key={colIndex} colSpan={colSpan} rowSpan={rowSpan} className={cellValue === "" && workbook.Sheets[firstSheet][cellRef]?.s?.b ? "empty-cell" : ""}>
                                                                {cellValue}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )} */}
                        </div>

                        <div id='main' style={{ flexDirection: 'column', display: 'flex' }}>


                            {/* <sectionheader id={"cs"} className="secHead">
                                CSRD
                            </sectionheader> */}

                            <sectionheader id={"es"} className="secHead">
                                Executive Summary
                            </sectionheader>


                            <sectionheader1 >
                                <span className="text-bold">   Overview of Sustainability Strategy</span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(236, 'textarea-1728277384520-0')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">  Key Achievements and Highlights</span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(236, 'textarea-1728277407667')}

                            </div>

                            <sectionheader1>
                                <span className="text-bold"> Future Outlook</span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(236, 'textarea-1728277424458')}

                            </div>




                            <sectionheader id={"int"} className="secHead">
                                Introduction
                            </sectionheader>


                            <sectionheader1>
                                <span className="text-bold"> Purpose of the Report </span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(237, 'textarea-1728277479166-0')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold"> Reporting Scope and Boundary </span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(237, 'textarea-1728277495368')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold"> Reporting Period and Entity </span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(237, 'textarea-1728277518187')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">Reporting Standards and Frameworks Used </span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(237, 'textarea-1728277537207')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">Specific Circumstances (e.g., Mergers, Business Model Changes)  </span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(237, 'textarea-1728277558063')}
                            </div>




                            <sectionheader id={"ma"} className="secHead">
                                Materiality Assessment
                            </sectionheader>



                            <sectionheader1>
                                <span className="text-bold">
                                    Materiality Assessment Process
                                </span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(238, 'textarea-1728277803906-0')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Double Materiality: Impact on Sustainability and Financial Performance
                                </span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(238, 'textarea-1728277819915')}
                            </div>



                            <sectionheader1>
                                <span className="text-bold">
                                    Stakeholder Engagement and Feedback Incorporation
                                </span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(238, 'textarea-1728277843426')}
                            </div>



                            <sectionheader1>
                                <span className="text-bold">
                                    Materiality Matrix
                                </span>
                            </sectionheader1>


                            <div class="display-box ">
                                {getLastResponseByRFID(238, 'textarea-1728277862234')}
                            </div>



                            <sectionheader id={"ed"} className="secHead">
                                Environmental Disclosures
                            </sectionheader>

                            <sectionheader id={"cc"} className="secHead">
                                Climate Change (ESRS E1)
                            </sectionheader>


                            <sectionheader1>
                                <span className="text-bold">
                                    Transition plan for climate change mitigation (E1-1)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(239, 'textarea-1728277916585-0')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Description of the processes to identify and assess material climate-related impacts, risks, and opportunities (IRO-1)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(239, 'textarea-1728277934847')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Policies implemented to manage climate change mitigation and adaptation (E1-2)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(239, 'textarea-1728277961276')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Measurable targets for climate change mitigation and adaptation (E1-3)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(239, 'textarea-1728277973033')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Climate change mitigation and adaptation action plans and resources (E1-4)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(239, 'textarea-1728277988973')}
                            </div>

                            {/* quantitaive pending */}
                            

                            
{renderEmissionProfileTable(`Climate Change  `)}




                            <sectionheader1>
                                <span className="text-bold">
                                    GHG removals and mitigation projects (E1-12 to E1-14)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(239, 'textarea-1728278216336')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Potential financial effects from material physical and transition risks (E1-15 to E1-16)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(239, 'textarea-1728278265289')}

                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Potential financial effects from climate-related opportunities (E1-17)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(239, 'textarea-1728278274927')}
                            </div>





                            <sectionheader id={"pe2"} className="secHead">
                                Pollution (ESRS E2)
                            </sectionheader>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="">
                                                Processes to identify and assess material pollution-related impacts, risks, and opportunities (IRO-1)
                                            </td>
                                            <td colspan="1" rowspan="1" className="">

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="">
                                                Policies and actions related to pollution (E2-1 to E2-2)
                                            </td>
                                            <td colspan="1" rowspan="1" className="">

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="">
                                                Pollution of air, water, and soil metrics (E2-3 to E2-4)
                                            </td>
                                            <td colspan="1" rowspan="1" className="">

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="">
                                                Substances of concern and financial effects from pollution-related risks (E2-5 to E2-6)
                                            </td>
                                            <td colspan="1" rowspan="1" className="">

                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>

                            <sectionheader id={"wam"} className="secHead">
                                Water and Marine Resources (ESRS E3)
                            </sectionheader>


                            <sectionheader1>
                                <span className="text-bold">
                                    Processes to identify and assess material water and marine resources-related impacts, risks and opportunities
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(241, 'textarea-1728278464807-0')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    E3-1 – Policies implemented to manage water and marine resources
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(241, 'textarea-1728278488970')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                E3-2 – Measurable targets for water and marine resources
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(241, 'textarea-1728278510707')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    E3-3 – Water and marine resources action plans and resources
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(241, 'textarea-1728278527224')}
                            </div>

                            {/* qantitaive pending */}
                            {/* <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colspan="1" rowspan="1" className="">
                                                Measurable targets for water and marine resources (E3-2)
                                            </td>
                                            <td colspan="1" rowspan="1" className="">

                                            </td>
                                        </tr>



                                        <tr>
                                            <td colspan="1" rowspan="1" className="">
                                                Water management performance (E3-4)
                                            </td>
                                            <td colspan="1" rowspan="1" className="">

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="">
                                                Water intensity performance (E3-5) (KL)
                                            </td>
                                            <td colspan="1" rowspan="1" className="">

                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div> */}

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>                                 <tr >
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Water withdrawal   
                                            </td>
                                        </tr>

                            {WaterWithdrawl_246(year)}
                            </tbody>

                            </table>
                            </div>




                            <sectionheader1>
                                <span className="text-bold">
                                    E3-6 – Marine resources-related performance
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(241, 'textarea-1728286579242-0')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    E3-7 – Potential financial effects from water and marine resources-related impacts, risks and opportunities
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(241, 'textarea-1728278577567')}
                            </div>



                            <sectionheader id={"bae"} className="secHead">
                                Biodiversity and Ecosystems (ESRS E4)
                            </sectionheader>


                            <sectionheader1>
                                <span className="text-bold">
                                    Material impacts, risks, and opportunities on biodiversity (SBM-3)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(242, 'textarea-1728278651054-0')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Processes to identify and assess material biodiversity and ecosystem-related impacts, risks, and opportunities (IRO-1)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(242, 'textarea-1728278666838')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Transition plan, policies, and targets for biodiversity management (E4-1 to E4-3)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(242, 'textarea-1728278688258')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Action plans and biodiversity metrics (Pressure, Impact, Response) (E4-4 to E4-7)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(242, 'textarea-1728278706889')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Biodiversity-friendly consumption, production, and offsets (E4-8 to E4-9)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(242, 'textarea-1728278732186')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Potential financial effects from biodiversity-related risks and opportunities (E4-10)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(242, 'textarea-1728278756757')}
                            </div>



                            <sectionheader id={"rac"} className="secHead">
                                Resource use and circular economy (ESRS E5)
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                    Resource use and circular economy integration in the business model (E5-1)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728278844363-0')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Processes to identify resource use and circular economy related impacts, risks and opportunities (E5-2)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728278856484')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Resource use and circular economy-related impacts, risks and opportunities - (E5-3)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728278876580')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Policies implemented to manage resource use and circular economy (E5-4)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728278893895')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Measurable targets for resource use and circular economy (E5-5)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728278911265')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Resource use and circular economy action plans (E5-6)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728278927466')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Resources inflows (E5-7)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728278961546')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Resources outflows (E5-8)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728278970148')}
                            </div>

                            {/* quantitative pending */}
                            {/* <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>



                                        <tr>
                                            <td colspan="1" rowspan="1" className="">
                                                Waste and emissions (E5-9) (MT)
                                            </td>
                                            <td colspan="1" rowspan="1" className="">

                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div> */}

<div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>                                 <tr >
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Water disposal   
                                            </td>
                                        </tr>

                            {WaterWithdrawl_246(year)}
                            </tbody>

                            </table>
                            </div>



                            <sectionheader1>
                                <span className="text-bold">
                                    Resource value strategy (E5-10)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728278988638')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Circular enablers (E5-11)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728278996122')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Taxonomy Regulation for the transition to a circular economy including enabling activities (E5-12)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728279013281')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Financial opportunities related to resource use and circular economy other than the Taxonomy Regulation (E5-13)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(243, 'textarea-1728279029278')}
                            </div>



                            <sectionheader id={"ow"} className="secHead">
                                Own Workforce (ESRS S1)
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                    Impacts on workforce originating from the business model (S1-1)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(244, 'textarea-1728279090590-0')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Material impacts on own workforce and worker types (S1-2)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(244, 'textarea-1728279105205')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Processes to remediate negative impacts and worker channels (S1-3 to S1-4)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(244, 'textarea-1728279119981')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Targets and actions for managing workforce impacts (S1-5 to S1-10)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(244, 'textarea-1728279150129')}
                            </div>

                            {/* quantitaive */}
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colspan="1" rowspan="1" className="">
                                                Employee characteristics (S1-11 to S1-12)
                                            </td>
                                            <td colspan="1" rowspan="1" className="">

                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>


                            <sectionheader id={"wvc"} className="secHead">
                                Workers in the Value Chain (ESRS S2)
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                    Policies and engagement processes for value chain workers (S2-1 to S2-2)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(245, 'textarea-1728279202957-0')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Channels for raising concerns and targets for value chain workers (S2-3 to S2-4)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(245, 'textarea-1728279217364')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                    Actions and approaches to mitigating risks for value chain workers (S2-5 to S2-6)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(245, 'textarea-1728279232870')}
                            </div>





                            <sectionheader id={"ac"} className="secHead">
                                Affected Communities (ESRS S3)
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                    Policies and engagement processes for affected communities (S3-1 to S3-2)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(246, 'textarea-1728279396547-0')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Channels for raising concerns and targets for community impacts (S3-3 to S3-4)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(246, 'textarea-1728279403063')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Actions and approaches to mitigating risks for communities (S3-5 to S3-6)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(246, 'textarea-1728279419005')}
                            </div>

                            <sectionheader id={"cae"} className="secHead">
                                Consumers and End-Users (ESRS S4)
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                    Policies and engagement processes for consumers and end-users (S4-1 to S4-2)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(247, 'textarea-1728279396547-0')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Channels for raising concerns and targets for consumer impacts (S4-3 to S4-4)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(247, 'textarea-1728279403063')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Actions and approaches to mitigating risks for consumers (S4-5 to S4-6)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(247, 'textarea-1728279419005')}
                            </div>


                            <sectionheader id={"gd"} className="secHead">
                                Governance Disclosures
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                    G1-1 Governance structure and composition
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(248, 'textarea-1728279475509-0')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    G1-2 Corporate governance code or policy
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(248, 'textarea-1728279484496')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    G1-3 Nomination process
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(248, 'textarea-1728279498455')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    G1-4 – Diversity policy
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(248, 'textarea-1728279527140')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    G1-5 – Evaluation process
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(248, 'textarea-1728279536167')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    G1-6 – Remuneration policy
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(248, 'textarea-1728279552775')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    G1-7 – Risk management processes
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(248, 'textarea-1728279567483')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    G1-8 – Internal control processes
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(248, 'textarea-1728279585100')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    G1-9 – Composition of the administrative, management and supervisory bodies
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(248, 'textarea-1728279599490')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    G1-10 – Meetings and attendance rate
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(248, 'textarea-1728279614421')}
                            </div>



                            <sectionheader id={"bce"} className="secHead">
                                Business Conduct (ESRS G1)
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                    Anti-corruption measures and ethical business conduct (G1-11)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(249, 'textarea-1728279663988-0')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    Legal and regulatory compliance (G1-12)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(249, 'textarea-1728279679842 ')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                    ESG risk management processes (G1-13)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                                {getLastResponseByRFID(249, 'textarea-1728279697864')}
                            </div>

                            <sectionheader id={"pai"} className="secHead">
                                Performance and Impact Reporting
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                    Impact Analysis and Effectiveness of Sustainability Strategies
                                </span>
                            </sectionheader1>

                            <div class="display-box ">

                                {getLastResponseByRFID(250, 'textarea-1728279766994-0')}
                            </div>


                            <sectionheader id={"anv"} className="secHead">
                                Assurance and Verification
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                External Assurance Summary
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                            {getLastResponseByRFID(251 , 'textarea-1728279816201-0')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                Scope of Assurance and Audit Findings
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                            {getLastResponseByRFID(251 , 'textarea-1728279822799')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                Justification for Non-Disclosure (if applicable)
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                            {getLastResponseByRFID(251 , 'textarea-1728279822799')}
                            </div>

                            {/* <sectionheader id={"es"} className="secHead">
                                Digital Tagging and Accessibility
                            </sectionheader> */}

                        

                            <sectionheader id={"app"} className="secHead">
                                Appendices
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                Detailed Methodologies Used for Data Collection
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                            {getLastResponseByRFID(252 , 'textarea-1728279894663-0')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                Glossary of Terms and Definitions
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                            {getLastResponseByRFID(252 , 'textarea-1728279908899')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                Supplementary Data and Case Studies
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                            {getLastResponseByRFID(252 , 'textarea-1728279929486')}
                            </div>

                            <sectionheader id={"fok"} className="secHead">
                                Future Outlook
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                Strategic Outlook and Challenges
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                            {getLastResponseByRFID(253 , 'textarea-1728279977719-0')}
                            </div>

                            <sectionheader1>
                                <span className="text-bold">
                                Planned Initiatives and Changes in Sustainability Strategy
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                            {getLastResponseByRFID(253 , 'textarea-1728279990365')}
                            </div>

                            <sectionheader id={"cin"} className="secHead">
                                Contact Information
                            </sectionheader>

                            <sectionheader1>
                                <span className="text-bold">
                                Contact Details for Further Information
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                            {getLastResponseByRFID(254 , 'textarea-1728280026193-0')}
                            </div>


                            <sectionheader1>
                                <span className="text-bold">
                                Feedback and Inquiries
                                </span>
                            </sectionheader1>

                            <div class="display-box ">
                            {getLastResponseByRFID(254 , 'textarea-1728280042553')}
                            </div>


                            


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CSRDReport, comparisonFn);
