import React, { useEffect, useRef, useState } from "react";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import $ from "jquery";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import FileSaver, { saveAs } from "file-saver";
import { InputTextarea } from "primereact/inputtextarea";
import { TabMenu } from "primereact/tabmenu";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { MultiSelect } from "primereact/multiselect";
import { ListBox } from "primereact/listbox";
import { Checkbox } from "primereact/checkbox";
import { DateTime } from "luxon";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { FilterMatchMode, FilterService } from 'primereact/api';
import { Divider } from "primereact/divider";
import { Tag } from "primereact/tag";
import { checkRoleAccessByRoleIds } from "../../components/BGHF/helper";
import { useParams } from 'react-router-dom';
import { Tree } from "primereact/tree";
// import { position } from "html2canvas/dist/types/css/property-descriptors/position";

const AssignApproverstoIndicator = () => {
    const fymonth = 1
    const [label1, label2, label3] = useSelector((state) => state.user.tierLabel);
    const userList_ = useSelector(state => state.userlist.userList)

    const level_list = [{ name: label1, id: 1 }, { name: label2, id: 2 }, { name: label3, id: 3 }]
    const [approverList, setApproverList] = useState([])
    const [rawsitelist, setRawSiteList] = useState([])
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    const admin_data = useSelector((state) => state.user.userdetail);
    const [load, setLoading] = useState(true)
    const [submitted, setSubmitted] = useState(false)
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isEndDateDisabled, setIsEndDateDisabled] = useState(false);
    const selector = useSelector(state => state.user.userdetail)
    const { id } = useParams(); // Retrieve the id from URL parameters
    const forceUpdate = useForceUpdate();
    const [indicatorlist, setIndicatorList] = useState([])
    const [indicatorassdialog, setIndicatorAssDialog] = useState(false)
    const [indicatorAssignments, setIndicatorAssignments] = useState([]); // New state for assignments
    const entryStartDate = DateTime.fromISO(admin_data.information.startdate, { zone: 'utc' }).toLocal().toJSDate()

    const [indicatorassobj, setIndicatorAssObj] = useState({
        indicatorId: null,
        start_date: null,
        end_date: null,
        levelOfApproval: null,
        // frequencyOfApproval: null,
        responsibility: [],
        threshold: false,
        tvalue1: null,
        tvalue2: null

    })
    useEffect(() => {
        let uriString = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }


        const promise0 = APIServices.get(API.AssignDCFClient_UP(admin_data.id))
        const promise1 = APIServices.get(API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise2 = APIServices.get(API.GetRole_Up(admin_data.id))
        const promise3 = APIServices.get(
            API.LocationOne_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`
        );
        Promise.all([promise0, promise1, promise2, promise3]).then((values) => {

            let indicator_list = []
            const shapedCategory = values[1].data.map(item => {
                if (item.newTopics) {
                    item.newTopics = item.newTopics.filter(topics =>
                        topics.newMetrics && topics.newMetrics.length > 0
                    );
                }
                return item;
            }).filter(item => item.newTopics && item.newTopics.length > 0)
           const shapedSite = values[3].data.map((item) => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(
                        (locationTwo) =>
                            locationTwo.locationThrees &&
                            locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter((item) => item.locationTwos && item.locationTwos.length > 0);
            setRawSiteList(shapedSite)
            console.log("Shaped Category:", shapedCategory); // Log the shaped category

            setApproverList(userList_.filter(i => (i.role === 'clientadmin' || values[2].data.filter(x => x.user_id === i.id && x.roles.includes(3)).length)).map(i => ({ name: i.information.empname, id: i.id })))

            if (values[0].data.length !== 0) {
                // DF
                shapedCategory.flatMap(i => i.newTopics).forEach((top) => {
                    if (values[0].data[0].topic_ids.includes(top.id) && (top.tag === null || parseFloat(top.tag) === admin_data.id)) {
                        top.newMetrics.forEach((met) => {

                            if ((Array.isArray(met.data1) && met.data1.length && met.data1[0].type === 0) && values[0].data[0].metric_ids.includes(met.id) && !indicator_list.map(i => i.id).includes(met.id) && (met.tag === null || parseFloat(met.tag) === admin_data.id)) {
                                indicator_list.push(met)

                            }
                        })
                    }
                })
                console.log("Filtered Indicator List:", indicator_list); // Log the final filtered list
                // setIndicatorList(filterRequiredDerivedAndStandaloneWithChildIds(indicator_list,shapedCategory.flatMap(i => i.newTopics && i.newTopics.flatMap(x => x.newMetrics )) ))
                setIndicatorList(indicator_list)

                console.log(indicatorlist)

            }
            setLoading(false)


        })
    }, [])
    const buildTreeNodes = (locations) => {
        return locations.map((location) => ({
            key: location.id,
            label: location.name,
            // Disable based on the disabled array
            children: location.locationTwos
                ? buildTreeNodes(location.locationTwos.map(locTwo => ({
                    ...locTwo,
                    locationThrees: locTwo.locationThrees // Include nested children
                })))
                : location.locationThrees
                    ? buildTreeNodes(location.locationThrees)
                    : null
        }));
    };
    function filterRequiredDerivedAndStandaloneWithChildIds(data, overalldata) {
        const childIds = new Set();
        const standaloneChildren = {};

        function collectStandalones(itemId) {
            const item = data.find(d => d.id === itemId);
            if (!item || !item.data1[0]) return [];

            const standaloneIds = [];
            const childIndicators = item.data1[0].indicator;

            childIndicators.forEach(id => {
                const child = overalldata.find(d => d.id === id);
                if (child) {
                    // Add child ID to the set for filtering later
                    childIds.add(child.id);

                    // Check if this child is standalone
                    if (child.data1[0]?.source === 1) {
                        standaloneIds.push(child.id);
                    } else {
                        // If not standalone, recursively collect standalone IDs from its children
                        standaloneIds.push(...collectStandalones(child.id));
                    }
                }
            });

            return standaloneIds;
        }

        // Step 1: Collect all standalone IDs for derived parents
        data.forEach(item => {
            if (item.data1[0]?.type === 0 && item.data1[0]?.source === 0) {
                const standaloneIds = collectStandalones(item.id);
                if (standaloneIds.length > 0) {
                    standaloneChildren[item.id] = standaloneIds;
                }
            }
        });

        // Step 2: Construct the result set
        const filteredData = data.map(item => ({
            ...item,
            standalone_ids: standaloneChildren[item.id] || [item.id],
        }));

        // Step 3: Filter out derived children from the result
        return filteredData.filter(item => !childIds.has(item.id));
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await APIServices.get(API.Indicator_Approver_Ass_UP(selector.id));
                // if (res.data.end_date) {
                //     setIsEndDateDisabled(true); // Disable end date if it exists
                // }   
                //setIndicatorAssignments(res.data); // Set the fetched data to state

                setIndicatorAssignments(res.data.filter(x => JSON.parse(JSON.stringify(indicatorlist)).map(i => i.id).includes(x.indicatorId)));
                setLoading(false); // Stop loading state once data is fetched
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();


    }, [indicatorlist]);

    const filterIndicator = () => {

        return isEditing ? indicatorlist : indicatorlist.filter(value => indicatorAssignments.filter(x => x.indicatorId === value.id).every(y => y.end_date))


    }



    const openDialog = (item) => {
        setSubmitted(false)
        setIndicatorAssObj({ levelOfApproval: null, frequencyOfApproval: null, responsibility: [], threshold: false, tvalue1: null, tvalue2: null })
        setIndicatorAssDialog(true)

    }

    // const updateIndicatorAssobj = (obj, val) => {
    //     let loc = indicatorassobj
    //     loc[obj] = val

    //     console.log(loc[obj], obj)
    //     // setIndicatorAssObj((prev) => ({ ...prev, ...loc }))
    //     setIndicatorAssObj(loc)
    //     forceUpdate()

    // }

    const updateIndicatorAssobj = (obj, val) => {
        // Create a new copy of the indicatorassobj
        let updatedObj = { ...indicatorassobj };

        // Update the desired field immutably
        updatedObj[obj] = val;

        console.log(updatedObj[obj], obj);

        // Update the state with the new object
        setIndicatorAssObj(updatedObj);
    };


    const newIndicatorAssObj = () => {
        setIndicatorAssObj({
            indicatorId: null,
            levelOfApproval: null,
            // frequencyOfApproval: null,
            responsibility: [],
            threshold: false,
            tvalue1: null,
            tvalue2: null,
            start_date: null,

        })
        setIsEditing(false)
        setIndicatorAssDialog(true)
        setSubmitted(false)

    }

    const renderHeader = () => {
        return (
            <div className="table-header-container">


                <div className="table-header-actions py-4 d-flex justify-content-between">

                    <Button onClick={() => { newIndicatorAssObj(); setVisible(true); }} label="Add New" icon="pi pi-plus" className="p-button-primary" /* Add click event logic here */ />
                </div>
            </div>
        );
    };

    // Helper function to get the indicator title by its ID
    const getIndicatorTitleById = (id) => {
        const indicator = indicatorlist.find((item) => item.id === id);
        return indicator ? indicator.title : "Unknown";
    };

    const getApprovalById = (id) => {
        const approval = [{ name: 'Corporate', id: 0 }, ...level_list].find((item) => item.id === id);
        return approval ? approval.name : "Unknown";
    };

    const getFrequencyById = (id) => {
        const freq = frequency_list.find((item) => item.id === id);
        return freq ? freq.name : "Unknown";
    };

    const getResponsibilityById = (ids) => {

        const responsibilityMap = approverList.reduce((map, item) => {
            map[item.id] = item.name;
            return map;
        }, {});

        return ids.split(", ").map(id => responsibilityMap[id] || id).join(", ");

    };


    // Load indicator assignments from local storage on mount
    // useEffect(() => {
    //     const savedAssignments = JSON.parse(localStorage.getItem('indicatorAssignments')) || [];
    //     setIndicatorAssignments(savedAssignments);
    // }, []);




    // const SaveAssignment = (userId) => {

    //     setSubmitted(true); // This triggers the validation messages


    //     let loc = indicatorassobj
    //     let local2 = indicatorAssignments
    //     // Check if all required fields are filled
    //     if (
    //         indicatorassobj.indicatorId !== null &&
    //         indicatorassobj.levelOfApproval !== null &&
    //         indicatorassobj.frequencyOfApproval !== null &&
    //         indicatorassobj.start_date !== null &&
    //         indicatorassobj.end_date_date !== null &&
    //         indicatorassobj.responsibility.length !== 0 &&

    //           (!indicatorassobj.threshold || 
    //     (indicatorassobj.threshold && indicatorassobj.tvalue1 !== null && indicatorassobj.tvalue2 !== null))
    //     ) 
    //     {

    //          APIServices.post(API.Indicator_Assignment_Add(selector.id),indicatorassobj).then((res) => {

    //         local2.push(res.data);


    //         setIndicatorAssObj({ 

    //             levelOfApproval: null, 
    //             frequencyOfApproval: null,
    //              responsibility: [],
    //               threshold: false,
    //                tvalue1: null, tvalue2: null,
    //                indicatorId:null,
    //                start_date:null ,

    //         });

    //         setIndicatorAssignments(local2)
    //         forceUpdate()
    //         setIndicatorAssDialog(false)
    //         setSubmitted(false)
    //     })


    // }


    // }

    const SaveAssignment = (userId) => {
        setSubmitted(true); // This triggers the validation messages
        let loc = indicatorassobj
        let local2 = indicatorAssignments;

        // Check if all required fields are filled, including end_date
        if (
            indicatorassobj.indicatorId !== null &&
            indicatorassobj.levelOfApproval !== null &&
            // indicatorassobj.frequencyOfApproval !== null &&
            indicatorassobj.start_date !== null &&
            // indicatorassobj.end_date !== null && 
            (!isEditing || (isEditing && indicatorassobj.end_date !== null)) &&
            indicatorassobj.responsibility.length !== 0 &&
            (!indicatorassobj.threshold ||
                (indicatorassobj.threshold && indicatorassobj.tvalue1 !== null && indicatorassobj.tvalue2 !== null))
        ) {
            if (isEditing && indicatorassobj.id) {
                let newObj = { ...indicatorassobj }
                delete newObj.isEndDateDisabled
                // If editing, use PATCH
                APIServices.patch(API.Indicator_Approver_Ass_Edit(indicatorassobj.id), newObj).then((res) => {
                    // Find the edited assignment and update it in the local state
                    const updatedAssignments = local2.map((item) =>
                        item.id === indicatorassobj.id ? {
                            ...item, ...indicatorassobj

                        } : item
                    );



                    setIndicatorAssignments(updatedAssignments);

                    // setIsEndDateDisabled(true)
                    resetForm();
                    setIndicatorAssDialog(false);
                    setSubmitted(false);

                    console.log('Before update:', local2);
                    console.log('Updating assignment:', indicatorassobj);
                });
            } else {

                // Otherwise, use POST for new assignments
                APIServices.post(API.Indicator_Approver_Ass_UP(selector.id), indicatorassobj).then((res) => {
                    local2.push({ ...res.data });


                    // Reset the form
                    resetForm();
                    setIndicatorAssignments(local2);
                    forceUpdate();
                    setIndicatorAssDialog(false);
                    setSubmitted(false);
                });
            }

        }
    };

    const resetForm = () => {
        setIndicatorAssObj({
            levelOfApproval: null,
            // frequencyOfApproval: null,
            responsibility: [],
            threshold: false,
            tvalue1: null,
            tvalue2: null,
            indicatorId: null,
            start_date: null,
            end_date: null, // Make sure to include end_date
        });
    };








    const dateFormating = (input) => {
        let dateTime;

        // Parse input into Luxon DateTime object
        if (typeof input === 'string') {

            // Parse UTC string
            dateTime = DateTime.fromISO(input, { zone: 'utc' });
        } else if (input instanceof Date) {

            // Convert JavaScript Date object to Luxon DateTime object
            dateTime = DateTime.fromJSDate(input);
        } else {
            throw new Error('Invalid input type. Expected UTC string or Date object.');
        }

        // Format DateTime object into "dd-LLL-yyyy" format
        return dateTime.toLocal().toFormat('yyyy');
    }


    const startDateTemplate = (rowData) => {
        return (
            <div>

                {dateFormating(rowData.start_date)}

            </div>
        )
    }


    const endDateTemplate = (rowData) => {
        console.log('End Date:', rowData.end_date); // Log to check the value
        return (
            <div>
                {rowData.end_date ? dateFormating(rowData.end_date) : '-'}
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editVal(rowData)
                        }}
                    />
                    {/* <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    /> */}
                </div>

            </>
        );
    };

    const editVal = (item) => {


        // items.startDate = DateTime.fromISO(items.startDate, { zone: 'utc' }).toJSDate()
        // items.completionDate = DateTime.fromISO(items.completionDate, { zone: 'utc' }).toJSDate()
        let items = JSON.parse(JSON.stringify(item))
        // items.startDate = DateTime.fromISO(items.startDate, { zone: 'utc' }).toJSDate()

        // Set the state with all necessary fields
        setIndicatorAssObj({
            levelOfApproval: items.levelOfApproval,
            // frequencyOfApproval: items.frequencyOfApproval,
            responsibility: items.responsibility,
            threshold: items.threshold,
            tvalue1: items.tvalue1,
            tvalue2: items.tvalue2,
            indicatorId: items.indicatorId,
            start_date: items.start_date, // Ensure this is properly set as well
            end_date: items.end_date,     // Add the end date here
            id: items.id,
            isEndDateDisabled: items.end_date ? true : false
            // Important for PATCH
        });


        setIsEditing(true); // Set editing mode to true
        setIndicatorAssDialog(true); // Open the dialog
    };

    const getMinDate = (indicatorId) => {
        let loc = JSON.parse(JSON.stringify(indicatorAssignments))
        const index = loc.findIndex(i => i.indicatorId === indicatorId)

        if (index !== -1) {
            let check = loc.filter(i => i.indicatorId === indicatorId).every(i => i.end_date)

            if (check) {
                console.log(loc.filter(i => i.indicatorId === indicatorId).sort((a, b) => a.id - b.id))
                return DateTime.fromISO(loc.filter(i => i.indicatorId === indicatorId).sort((a, b) => b.id - a.id)[0].end_date, { zone: 'utc' }).toLocal().plus({ year: 1 }).toJSDate()
            } else {
                return DateTime.fromISO(loc.filter(i => i.indicatorId === indicatorId).sort((a, b) => b.id - a.id)[0].start_date, { zone: 'utc' }).toLocal().toJSDate()
            }
        } else {
            return entryStartDate
        }
    }
    const getMaxDate = (indicatorId) => {

        const index = indicatorAssignments.findIndex(i => i.indicatorId === indicatorId)

        if (index !== -1) {
            let check = indicatorAssignments.filter(i => i.indicatorId === indicatorId).every(i => i.end_date)
            console.log(indicatorAssignments.sort((a, b) => a.id - b.id))
            if (check) {
                console.log(indicatorAssignments.filter(i => i.indicatorId === indicatorId).sort((a, b) => a.id - b.id))
                return DateTime.fromISO(indicatorAssignments.filter(i => i.indicatorId === indicatorId).sort((a, b) => b.id - a.id)[0].end_date, { zone: 'utc' }).toLocal().plus({ year: 1 }).toJSDate()
            } else {
                return DateTime.fromISO(indicatorAssignments.filter(i => i.indicatorId === indicatorId).sort((a, b) => b.id - a.id)[0].start_date, { zone: 'utc' }).toLocal().toJSDate()
            }
        } else {
            return entryStartDate
        }
    }

    const itemTemplate = (option) => {
        console.log(option)
        return (
            <div className="flex align-items-center ">

                <div>{option.name}</div>


            </div>
        );
    };
    const loaRowFilterTemplate = (options) => {

        return (
            <MultiSelect
                value={options.value}
                options={[{ name: 'Corporate', id: 0 }, ...level_list]}
                itemTemplate={itemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const indicatorRowFilterTemplate = (options) => {

        return (
            <MultiSelect
                value={options.value}
                options={indicatorlist.filter(x => indicatorAssignments.map(i => i.indicatorId).includes(x.id)).map(i => ({ name: i.title, id: i.id }))}
                itemTemplate={itemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }




    return (<div className="font-lato">
        <div style={{
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            fontWeight: '600', marginBottom: 10
        }} >Indicator Approver Assignment(s) {!load && '(' + indicatorAssignments.length + ')'}</div>

        {/* <div>
            {indicatorlist.map((i) => {
                return (
                    <div className="m-2 card col-12" >
                        <div className="grid col-12 m-0 p-0"> <div className="col-11">{i.title} </div>
                            <div className="col-1 flex justify-content-end"> <i className="pi pi-cog fs-16" onClick={() => { openDialog(i) }} /> </div>
                        </div>
                    </div>
                )
            })}
        </div> */}

        <DataTable

            value={indicatorAssignments}
            dataKey="id"
            filters={{ levelOfApproval: { value: null, matchMode: 'in' }, indicatorId: { value: null, matchMode: 'in' } }}
            header={renderHeader()}
            //   header={edit === 1 && renderHeader()}
            className="p-datatable-initiatives"
            scrollable // Enables scrollable body
        //scrollHeight="400px"
        >

            <Column field="indicatorId" showFilterMatchModes={false} filter showApplyButton={false} filterElement={indicatorRowFilterTemplate} header="Indicator" body={(rowData) => getIndicatorTitleById(rowData.indicatorId)} />

            <Column field="levelOfApproval" showFilterMatchModes={false} filter showApplyButton={false} filterElement={loaRowFilterTemplate} header="Level of Approval" body={(rowData) => getApprovalById(rowData.levelOfApproval)} />

            {/* <Column field="frequencyOfApproval" header="Approver Frequency" body={(rowData) => getFrequencyById(rowData.frequencyOfApproval)} /> */}

            <Column
                body={startDateTemplate}
                field="startDate" header="Start Year"
            />

            <Column
                body={endDateTemplate}
                field="endDate" header="End Year"
            />

            {/* <Column field="threshold" header="Threshold" body={(rowData) => (rowData.threshold ? `${rowData.tvalue1} to ${rowData.tvalue2}` : "N/A")} /> */}

            <Column field="threshold" header="Threshold" body={(rowData) => (rowData.threshold ? (rowData.tvalue1 + ' to ' + rowData.tvalue2) : "No")} />


            <Column field="responsibility" header="Responsibility" body={(rowData) => getResponsibilityById(rowData.responsibility.join(", "))} />

            <column field="action" header="Action "
                body={actionBodyTemplate}
            />

        </DataTable>

        <Dialog
            visible={indicatorassdialog}
            style={{
                width: "75%",
            }}
            header={"Assignment of Indicator"}
            modal
            className="p-fluid"

            onHide={() => { setIndicatorAssDialog(false) }}

        >

            <div>

                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10,
                            }}
                        >
                            Indicator
                        </label>
                    </div>
                    <div className="col-7 ddf">
                        <Dropdown
                            style={{ width: '100%', height: '100%' }}
                            value={indicatorassobj.indicatorId} // Set value for selected indicator
                            optionValue="id"

                            options={filterIndicator()} // Use indicatorlist as dropdown options
                            onChange={(e) => updateIndicatorAssobj("indicatorId", e.value)} // Store the selected indicator in your state
                            optionLabel="title" // Show the title of the indicator
                            placeholder="Select Indicator" // Adjust placeholder to reflect indicator selection
                            disabled={isEditing}
                        />


                    </div>

                </div>

                {indicatorassobj.indicatorId && (

                    <>
                        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div className="col-5">
                                <label
                                    style={{
                                        margin: 10,
                                    }}
                                >
                                    Set Level of Approval
                                    <span className='mandatory'>*</span>
                                </label>
                            </div>
                            <div className="col-7 ddf">
                                <Dropdown style={{ width: '100%', height: '100%' }} value={indicatorassobj.levelOfApproval} optionValue="id" options={[{ name: 'Corporate', id: 0 }, ...level_list]} onChange={(e) => updateIndicatorAssobj("levelOfApproval", e.value)} optionLabel="name" placeholder="Select frequency" disabled={isEditing} />

                                {submitted && (indicatorassobj.levelOfApproval === null) &&
                                    (

                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >

                                            Select Set Level of Approval
                                        </small>

                                    )}


                            </div>


                        </div>



                        {/* <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10,
                            }}
                        >
                            Set Approver Frequency
                            <span className='mandatory'>*</span>
                        </label>
                    </div>
                    <div className="col-7 ddf">
                        <Dropdown style={{ width: '100%', height: '100%' }} value={indicatorassobj.frequencyOfApproval} optionValue="id" options={frequency_list} onChange={(e) => updateIndicatorAssobj("frequencyOfApproval", e.value)} optionLabel="name" placeholder="Select frequency" disabled={isEditing}/>

                        {submitted && (indicatorassobj.frequencyOfApproval === null) &&
                            (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red"
                                    }}
                                >

                                    Select Approver Frequency
                                </small>
                            )}

                    </div>



                </div> */}

                        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div className="col-5">
                                <label
                                    style={{
                                        margin: 10,
                                    }}
                                >
                                    Start Year
                                    <span className='mandatory'>*</span>
                                </label>
                            </div>
                            <div className="col-7 ddf">

                                <Calendar className='mt-2' id="start_date" value={indicatorassobj.start_date ? DateTime.fromISO(indicatorassobj.start_date, { zone: 'utc' }).toLocal().toJSDate() : null}
                                    onChange={(e) => { updateIndicatorAssobj("start_date", e.value.toISOString()) }}
                                    view="year"
                                    minDate={getMinDate(indicatorassobj.indicatorId)}
                                    maxDate={getMinDate(indicatorassobj.indicatorId)}

                                    dateFormat="yy"


                                    showIcon
                                    disabled={isEditing}

                                />

                                {submitted && (indicatorassobj.start_date === null) &&
                                    (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red"
                                            }}
                                        >

                                            Select Start Date      </small>
                                    )}

                            </div>



                        </div>

                        {isEditing && (
                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        End Year
                                        <span className='mandatory'>*</span>
                                    </label>
                                </div>

                                <div className="col-7 ddf">

                                    <Calendar className='mt-2' id="end_date"
                                        minDate={DateTime.fromISO(indicatorassobj.start_date, { zone: 'utc' }).toLocal().toJSDate()}
                                        // value={indicatorassobj.end_date } 

                                        value={indicatorassobj.end_date ? DateTime.fromISO(indicatorassobj.end_date, { zone: 'utc' }).toLocal().toJSDate() : null}
                                        onChange={(e) => { updateIndicatorAssobj("end_date", e.value.toISOString()) }}
                                        view="year"            // View limited to year
                                        dateFormat="yy"        // Display only the year

                                        showIcon
                                        disabled={indicatorassobj.isEndDateDisabled} // Disable if true




                                    />

                                    {submitted && (indicatorassobj.end_date === null) &&
                                        (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red"
                                                }}
                                            >

                                                Select End Date      </small>
                                        )}

                                </div>



                            </div>
                        )}

                        {/* Threshold Checkbox */}
                        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                            <div className="col-5 ">
                                <Checkbox inputId="threshold" checked={indicatorassobj.threshold} onChange={e => updateIndicatorAssobj("threshold", e.checked)}
                                    style={{
                                        margin: 10,
                                    }} />
                                <label htmlFor="threshold" className="p-checkbox-label" style={{ margin: 10 }}>
                                    Threshold  <span className='mandatory'>*</span></label>
                            </div>

                            <div className="col-7">
                                {indicatorassobj.threshold && (
                                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className="col-3" style={{ width: '50%', height: '50%' }}>
                                            <InputNumber value={indicatorassobj.tvalue1} onValueChange={(e) => updateIndicatorAssobj("tvalue1", e.value)}

                                                placeholder="Enter number" min={-100} max={0}


                                            />
                                            {submitted && indicatorassobj.threshold && (indicatorassobj.tvalue1 === null || indicatorassobj.tvalue2 === null) && (
                                                <small className="p-invalid" style={{ color: "red" }}>
                                                    Please fill min threshold values
                                                </small>
                                            )}
                                        </div>
                                        <div className="col-1" style={{ textAlign: 'center' }}>
                                            TO
                                        </div>
                                        <div className="col-3" style={{ width: '50%', height: '50%' }}>
                                            <InputNumber value={indicatorassobj.tvalue2} onValueChange={(e) => updateIndicatorAssobj("tvalue2", e.value)} placeholder="Enter number" min={0} max={100} />

                                            {submitted && indicatorassobj.threshold && (indicatorassobj.tvalue1 === null || indicatorassobj.tvalue2 === null) && (
                                                <small className="p-invalid" style={{ color: "red" }}>
                                                    Please fill max threshold values
                                                </small>
                                            )}

                                        </div>



                                    </div>
                                )}
                            </div>

                        </div>



                        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div className="col-5">
                                <label
                                    style={{
                                        margin: 10,
                                    }}
                                >
                                    Responsibility
                                    <span className='mandatory'>*</span>
                                </label>
                            </div>
                            <div className="col-7 ddf">
                                <MultiSelect style={{ width: '100%', height: '100%' }} value={indicatorassobj.responsibility} optionValue="id" options={approverList} onChange={(e) => updateIndicatorAssobj("responsibility", e.value)} optionLabel="name" placeholder="Select Responsibility" />

                                {submitted && (indicatorassobj.responsibility.length === 0) &&
                                    (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red"
                                            }}
                                        >

                                            Select Responsibility
                                        </small>
                                    )}

                            </div>
                        </div>

                    </>
                )}


                <Tree value={buildTreeNodes(rawsitelist)} selectionMode="checkbox" />

                <div className="flex justify-content-end">
                    <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { SaveAssignment() }}>Save Assignment </Button>
                </div>




            </div>
        </Dialog>
    </div>)
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AssignApproverstoIndicator, comparisonFn);